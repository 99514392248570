import React, { useState, useEffect } from "react"
import { Helmet } from "react-helmet";

// import HiveIndexTopics from './hiveindextopics'

import { Transition } from "@headlessui/react";
import ReactTooltip from 'react-tooltip';

import { collectionsService } from '../../services/collections';
import { hasPro, hasStarter } from '../../utils/account';

import { defaultParams } from './constants'

const Filters = ({filters, apply, close, clearFilters, currentUser}) => {

	// state


	// form state
	const [keyword, setKeyword] = useState(filters.keyword, filters.keyword);
  const [subreddits, setSubreddits] = useState(filters.collection ? '' : filters.subreddits, filters.subreddits);
	const [usernames, setUsernames] = useState(filters.usernames, filters.usernames);
	const [backend, setBackend] = useState(filters.backend || currentUser.env.default_reddit_backend, filters.backend);
	const [type, setType] = useState(filters.type, filters.type);
  const [collection, setCollection] = useState(filters.collection, filters.collection);
	const [timeframe, setTimeframe] = useState(filters.timeframe, filters.timeframe);
  const [limit, setLimit] = useState(filters.limit, filters.limit);
  const [beforeTimestamp, setBeforeTimestamp] = useState(filters['before-timestamp'], filters['before-timestamp']);
	const [sort, setSort] = useState(filters.sort, filters.sort);
  const [excludeKeyword, setExcludeKeyword] = useState(filters['exclude-keyword'], filters['exclude-keyword']);
  const [excludeSubreddits, setExcludeSubreddits] = useState(filters['exclude-subreddits'], filters['exclude-subreddits']);
  const [excludeUsernames, setExcludeUsernames] = useState(filters['exclude-usernames'], filters['exclude-usernames']);
  const [excludeDuplicates, setExcludeDuplicates] = useState(filters['exclude-duplicates'], filters['exclude-duplicates']);
  const [advancedOpen, setAdvancedOpen] = useState(false);
  const [allCollections, setAllCollections] = useState([]);
  const [loadingCollections, setLoadingCollections] = useState(false);
  const isProSubscriber = hasPro(currentUser);
	// const [topics, setTopics] = useState(null);

	// effects
  useEffect(() => {
    setSubreddits(filters.subreddits || '')
    setUsernames(filters.usernames || '')
    setTimeframe(filters.timeframe || defaultParams.timeframe)
    setBeforeTimestamp(filters['before-timestamp'] ? (new Date(filters['before-timestamp'] * 1000)).toISOString().split('T')[0] : '')
  }, [
    filters.subreddits,
    filters.timeframe,
    filters.usernames,
    filters['before-timestamp'],
  ]); // Run when filters update from an external component (user applying frontend filters to API filters)

  // effects
  useEffect(() => {
    setLoadingCollections(true)
    collectionsService.getCollections((results) => {
      if (results && results.data){
        setAllCollections(results.data)
        setLoadingCollections(false)

        // if we have one selected, set that as the option
        if (filters.collection){
          const c = results.data.filter(c => c.hash === filters.collection)[0];
          setCollection(c && c.hash)
        }
      }
    }, (response, error) => {
      setLoadingCollections(false)
    })
  }, []); // Load up lists of collections

	// actions
	const submitForm = (e) => {
		if (e){
      e.preventDefault();  // to stop page refresh
    }

		// const form = e.target;
		// only send values if they have actually changes
		const values = {
			'subreddits': collection ? '' : subreddits.replace(/(\r\n|\n|\r)/gm, ","),  // TODO: validate real subs
			'collection': collection,
      'usernames': usernames ? usernames.replace(/(\r\n|\n|\r)/gm, ",") : '',
      'keyword': keyword,
      'type': type,
			'backend': backend,
			'sort': sort,
			'timeframe': timeframe,
      'limit': limit,
      'before-timestamp': beforeTimestamp ? (new Date(beforeTimestamp)).getTime() / 1000 : '',
      'exclude-usernames': excludeUsernames,
      'exclude-subreddits': excludeSubreddits,
      'exclude-keyword': excludeKeyword,
      'exclude-duplicates': excludeDuplicates,
		}
    // console.log("yep", values)
    // console.log("v", beforeTimestamp, new Date(beforeTimestamp))
		apply(values); // send it!

    if (close){
      close()
    }
	}

	const filtersApplied = keyword || subreddits || collection || usernames || (timeframe !== defaultParams.timeframe) || !['submissions', 'comments'].includes(type);
  
	return (
	  <div className="lg:min-w-0 lg:flex-1">
      <Helmet><title>Conversations | Reddit | Filters</title></Helmet>

	    <div className="relative">
	      <div className="flex items-center">
	        <h2 className="flex-1 flex text-lg font-medium items-center">
	        	Modify Reddit Query
	        	{false && filtersApplied ? (
	        		<p className="ml-2 mt-1 text-xs text-cyan-600">
	              <span className="cursor-pointer" onClick={clearFilters}>clear filters</span>
	            </p>
	        	) : ''}
	        </h2>

          <button type="submit" className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
            // disabled={!keyword && !collection && !subreddits}
            disabled={!filtersApplied}
            onClick={(e) => submitForm(e)}
          >
            Perform Search
          </button>
	      </div>

				<form className="space-y-8 divide-y divide-gray-700" onSubmit={submitForm}>
				  <div className="space-y-4 divide-y divide-gray-700">
				    

            <div className="pt-4">
              <label htmlFor="sort" className="block text-sm font-medium">
                Keyword
                <span className="text-red-500"> *</span>
              </label>
              <fieldset>
                <input name="keyword2" autoComplete="off" type="text"
                  className="mt-1 px-3 py-2 block w-full text-xs bg-gray-700 border border-gray-800 rounded-md focus:ring-cyan-500 focus:border-cyan-500"
                  value={keyword} onChange={(e) => setKeyword(e.target.value)}
                  placeholder="Keyword to search on Reddit"
                />
              </fieldset>

              {/*<label htmlFor="type" className="block text-sm font-medium pt-4">
                Search Type
              </label>
              <fieldset>
                <legend className="sr-only">Search Type</legend>
                <div className="flex">
                  <div className="relative py-2 flex items-center ml-2">
                    <div className="flex items-center h-5">
                      <input id="type-submissions" name="type" type="radio" className="focus:ring-cyan-500 h-4 w-4 text-cyan-600 cursor-pointer border-gray-800 bg-gray-600"
                        checked={type === 'submissions'}
                        onChange={(e) => setType('submissions')}
                      />
                    </div>
                    <label htmlFor="type-submissions" className="ml-2 cursor-pointer">
                      <span className="block text-sm font-medium flex items-center">
                        <svg className="h-4 w-4 opacity-50 mr-1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                        </svg>
                        Submissions
                        </span>
                    </label>
                  </div>

                  
                  <div className="relative py-2 flex items-center ml-6"
                    // disabled={backend === 'praw'}
                  >
                    <div className="flex items-center h-5">
                      <input id="type-comments" name="type" type="radio" className={`focus:ring-cyan-500 h-4 w-4 text-cyan-600 cursor-pointer border-gray-800 bg-gray-600`}
                        checked={type === 'comments'}
                        onChange={(e) => {
                          setType('comments')
                          if (backend !== "psaw"){
                            setBackend('psaw')
                          }
                        }}
                      />
                    </div>
                    <label htmlFor="type-comments" className="ml-2 cursor-pointer">
                      <span className={`block text-sm font-medium flex items-center`}>
                        <svg className="h-4 w-4 opacity-50 mr-1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z" />
                        </svg>
                        <span
                          // className={`${(backend !== "psaw") ? 'line-through' : ''}`}
                        >
                          Comments
                        </span>
                      </span>
                    </label>
                  </div>
                </div>
              </fieldset>*/}
            </div>

            <div className="pt-4">
              <div className="">
                <div className="">
                  <label htmlFor="sort" className="block text-sm font-medium">
                    Sort
                  </label>
                  <fieldset>
                    <legend className="sr-only">Sort</legend>
                    <div className="flex">
                      {["New", "Top", "Hot"].map(s => (
                        <div key={s} className={`relative py-2 flex items-center ml-2 mr-4 ${(s === "Hot") && (backend !== "praw") ? 'line-through' : ''}`}
                          disabled={(s === "Hot") && (backend !== "praw")}
                        >
                          <div className="flex items-center h-5">
                            <input id={`sort-${s.toLowerCase()}`} name="sort" type="radio"
                              className="focus:ring-cyan-500 h-4 w-4 text-cyan-600 cursor-pointer border-gray-800 bg-gray-600"
                              checked={sort === s.toLowerCase()} onChange={(e) => setSort(s.toLowerCase())}
                            />
                          </div>
                          <label htmlFor={`sort-${s.toLowerCase()}`} className="ml-2 cursor-pointer">
                            <span className="block text-sm font-medium flex items-center">{s}</span>
                          </label>
                        </div>
                      ))}
                    </div>
                  </fieldset>
                </div>

                <div className="mt-2">
                  <label htmlFor="limit" className="block text-sm font-medium flex items-center">
                    <div>Limit</div>

                    <ReactTooltip id={`limit-info`} place="right" effect="solid" backgroundColor="white" textColor="black" />
                  
                    {/* <span className="cursor-pointer ml-2" data-for='limit-info' data-tip="Increased query limits are available on the Pro subscription">
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 opacity-50" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                      </svg>
                    </span> */}
                  </label>

                  <fieldset disabled={!isProSubscriber}>
                    <legend className="sr-only">Limit</legend>
                    <div className="flex">
                      {[100, 200, 500].map(s => (
                        <div key={s} className="relative py-2 flex items-center ml-2 mr-4"
                          disabled={!isProSubscriber && (s > 100)}
                        >
                          <div className="flex items-center h-5">
                            <input id={`limit-${s}`} name="limit" type="radio"
                              className="focus:ring-cyan-500 h-4 w-4 text-cyan-600 cursor-pointer border-gray-800 bg-gray-600"
                              checked={parseInt(limit) === s} onChange={(e) => setLimit(s)}
                            />
                          </div>
                          <label htmlFor={`limit-${s}`} className="ml-2 cursor-pointer">
                            <span className="block text-sm font-medium flex items-center">{s}</span>
                          </label>
                        </div>
                      ))}
                    </div>
                  </fieldset>
                </div>
              </div>

              <div>
                <label htmlFor="type" className="block text-sm font-medium pt-2">
                  Timeframe
                </label>
                <fieldset>
                  <legend className="sr-only">Timeframe</legend>
                  <div className="flex flex-wrap">
                    {["All", "Year", "Month", "Week", "Day", "Hour"].map(t => (
                      <div key={t} className="relative py-2 flex items-center ml-2 mr-4">
                        <div className="flex items-center h-5">
                          <input id={`timeframe-${t.toLowerCase()}`} name="timeframe" type="radio" className="focus:ring-cyan-500 h-4 w-4 text-cyan-600 cursor-pointer border-gray-800 bg-gray-600"
                            checked={timeframe === t.toLowerCase()} onChange={(e) => setTimeframe(t.toLowerCase())}
                          />
                        </div>
                        <label htmlFor={`timeframe-${t.toLowerCase()}`} className="ml-2 cursor-pointer">
                          <span className="block text-sm font-medium flex items-center">{t}</span>
                        </label>
                      </div>
                    ))}
                  </div>
                </fieldset>
              </div>
            </div>

				    <div className="pt-4 md:flex md:space-x-4">
			    		{/*<h3 className="pb-4">Filters</h3>*/}
				      <div className="mb-4 flex-1">
                <div className="sm:col-span-4">
                  <label htmlFor="collection" className="block text-sm font-medium flex items-center">
                    <div>Select Audience</div>

                    <ReactTooltip id={`collection-info`} place="right" effect="solid" backgroundColor="white" textColor="black" />
                  
                    <span className="cursor-pointer ml-2" data-for='collection-info' data-tip="Collections are saved groups of Subreddits">
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 opacity-50" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                      </svg>
                    </span>
                  </label>
                  <div className="mt-1">
                    {loadingCollections ? (
                      <select
                        id="collection" name="collection"
                        className="mt-1 block opacity-50 w-full pl-3 pr-10 py-3 text-base bg-gray-700 border-gray-800 focus:outline-none focus:ring-cyan-500 focus:border-cyan-500 sm:text-sm rounded-md"
                        disabled
                      >
                        <option value=""></option>
                      </select>
                    ) : (
                      <select
                        id="collection"
                        name="collection"
                        className="mt-1 block w-full pl-3 pr-10 py-3 text-base bg-gray-700 border-gray-800 focus:outline-none focus:ring-cyan-500 focus:border-cyan-500 sm:text-sm rounded-md"
                        value={collection}
                        onChange={(e) => {
                          const hash = e.target.value;
                          setCollection(hash);
                          if (hash){
                            setTimeout(() => document.getElementById('subreddits').value = '', 10)
                          } else {
                            setTimeout(() => document.getElementById('subreddits').focus(), 10)
                          }
                        }}
                      >
                        <option value="">No audience - Searching all of Reddit</option>
                        {allCollections.map(c => (
                          <option key={c.hash} value={c.hash}>{c.name} - {c.count_items} {c.count_items === 1 ? 'Subreddit' : 'Subreddits'}</option>
                        ))}
                      </select>
                    )}
                  </div>
                  {/*<p className="mt-2 text-xs text-gray-500"></p>*/}
                </div>
              </div>

              <div className="flex-1">
				        <div className="sm:col-span-4">
                  <label htmlFor="subreddits" className={`block text-sm flex items-center font-medium ${collection ? 'line-through opacity-75' : ''}`}>
				            Individual Subreddits
				            <span className="ml-2 text-xs opacity-50">{subreddits ? subreddits.split(',').length : ''}</span>

                    <ReactTooltip id={`subreddit-info`} place="top" effect="solid" backgroundColor="white" textColor="black" />
                  
                    <span className="cursor-pointer ml-2" data-for='subreddit-info' data-tip={`Comma-separated-list, without "r/"`}>
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 opacity-50" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                      </svg>
                    </span>
                  </label>
				          <div className="mt-1">
				            <textarea id="subreddits" name="subreddits" rows="2" autoComplete="off"
                      className="shadow-sm focus:ring-cyan-500 focus:border-cyan-500 bg-gray-700 block w-full text-xs border-gray-800 rounded-md"
				            	// placeholder="entrepreneurship,product-management"
				            	placeholder='Leave empty to search all of Reddit'
				            	//autoFocus
                      disabled={collection}
				            	value={subreddits} onChange={(e) => setSubreddits(e.target.value)}></textarea>
				          </div>
			           	{/*<p className="mt-2 text-xs text-gray-500"></p>*/}
				        </div>
			      	</div>
			      	{/*<HiveIndexTopics currentSubreddits={subreddits} setSubreddits={setSubreddits} />*/}
				    </div>

            {!advancedOpen ? (
              <div className="pt-4">
                <div className="text-sm opacity-75 hover:opacity-100 cursor-pointer" onClick={() => setAdvancedOpen(true)}>
                  Show Advanced Options ↓
                </div>
              </div>
            ) : (
              <div className="pt-4">
                {/*<h3 className="pb-4">
                  Settings
                </h3>*/}

                <div className="sm:flex sm:space-x-4">
                  <div className="flex-1">
                    <label htmlFor="usernames" className={`block text-sm font-medium`}>
                      Specific Users
                      <span className="ml-2 text-xs opacity-50">{usernames ? usernames.split(',').length : ''}</span>
                    </label>
                    <div className="mt-1">
                      <textarea id="usernames" name="usernames" rows="2" autoComplete="off"
                        className="shadow-sm focus:ring-cyan-500 focus:border-cyan-500 bg-gray-700 block w-full text-xs border-gray-800 rounded-md"
                        // placeholder="entrepreneurship,product-management"
                        placeholder='Comma-separated-list, without "u/". Leave empty for all.'
                        //autoFocus
                        value={usernames} onChange={(e) => setUsernames(e.target.value)}></textarea>
                    </div>
                    {/*<p className="mt-2 text-xs text-gray-500"></p>*/}
                  </div>

                  <div className="flex-1">
                    <label htmlFor="exclude-usernames" className={`block text-sm font-medium`}>
                      Exclude Users
                      <span className="ml-2 text-xs opacity-50">{excludeUsernames ? excludeUsernames.split(',').length : ''}</span>
                    </label>
                    <div className="mt-1">
                      <textarea id="subreddits" name="exclude-usernames" rows="2" autoComplete="off"
                        className="shadow-sm focus:ring-cyan-500 focus:border-cyan-500 bg-gray-700 block w-full text-xs border-gray-800 rounded-md"
                        // placeholder="entrepreneurship,product-management"
                        placeholder='Comma-separated-list, without "u/".'
                        //autoFocus
                        value={excludeUsernames} onChange={(e) => setExcludeUsernames(e.target.value)}></textarea>
                    </div>
                    {/*<p className="mt-2 text-xs text-gray-500"></p>*/}
                  </div>
                </div>

                <div className="mt-4 sm:flex sm:space-x-4">
                  <div className="flex-1">
                    <label htmlFor="exclude-subreddits" className={`block text-sm font-medium`}>
                      Exclude Subreddits
                      <span className="ml-2 text-xs opacity-50">{excludeSubreddits ? excludeSubreddits.split(',').length : ''}</span>
                    </label>
                    <div className="mt-1">
                      <textarea id="exclude-subreddits" name="exclude-subreddits" rows="2" autoComplete="off"
                        className="shadow-sm focus:ring-cyan-500 focus:border-cyan-500 bg-gray-700 block w-full text-xs border-gray-800 rounded-md"
                        // placeholder="entrepreneurship,product-management"
                        placeholder='Comma-separated-list, without "r/."'
                        //autoFocus
                        value={excludeSubreddits} onChange={(e) => setExcludeSubreddits(e.target.value)}></textarea>
                    </div>
                    {/*<p className="mt-2 text-xs text-gray-500"></p>*/}
                  </div>

                  <div className="flex-1">
                    <label htmlFor="exclude-usernames" className={`block text-sm font-medium`}>
                      Exclude Keywords
                      <span className="ml-2 text-xs opacity-50">{excludeKeyword ? excludeKeyword.split(',').length : ''}</span>
                    </label>
                    <div className="mt-1">
                      <textarea id="subreddits" name="exclude-usernames" rows="2" autoComplete="off"
                        className="shadow-sm focus:ring-cyan-500 focus:border-cyan-500 bg-gray-700 block w-full text-xs border-gray-800 rounded-md"
                        // placeholder="entrepreneurship,product-management"
                        placeholder='Comma-separated-list, without spaces.'
                        //autoFocus
                        value={excludeKeyword} onChange={(e) => setExcludeKeyword(e.target.value)}></textarea>
                    </div>
                    {/*<p className="mt-2 text-xs text-gray-500"></p>*/}
                  </div>
                </div>

                {/*<div className="mt-4 sm:flex sm:space-x-4">
                  <div className="flex-1">
                    <label htmlFor="exclude-subreddits" className={`block text-sm font-medium ${backend !== 'psaw' ? 'line-through' : ''}`}>
                      Before Date
                    </label>
                    <div className="mt-1">
                      <input type="date" id="before" name="before" disabled={backend !== "psaw"} rows="2"
                        className="shadow-sm focus:ring-cyan-500 focus:border-cyan-500 bg-gray-700 block w-full text-xs border-gray-800 rounded-md"
                        placeholder='Optional Date mm/dd/yyyy'
                        value={beforeTimestamp} onChange={(e) => setBeforeTimestamp(e.target.value)}
                      ></input>
                    </div>
                  </div>

                  <div className="flex-1"></div>
                </div>*/}
                

                {/*{currentUser && currentUser.subscription ? (
                  <React.Fragment>
                    <label htmlFor="email" className="block text-sm font-medium pt-2 mt-2">
                      Data Source
                    </label>
                    <fieldset>
                      <legend className="sr-only">
                        Data Source
                      </legend>

                      <div className="rounded-md -space-y-px">
                        <div className="relative  rounded-tr-md p-2 flex">
                          <div className="flex items-center h-5">
                            <input id="backend-option-psaw" name="backend_option" type="radio" className="focus:ring-cyan-500 h-4 w-4 text-cyan-600 cursor-pointer border-gray-800 bg-gray-600"
                              checked={backend === 'psaw'}
                              onChange={(e) => setBackend('psaw')}
                            />
                          </div>
                          <label htmlFor="backend-option-psaw" className="ml-2 flex flex-col cursor-pointer">
                            <span className="block text-sm font-medium">
                              Pushshift.io
                            </span>
                            <span className="block text-xs opacity-75">
                              Cached results. Supports submissions + comments. Exact keyword match.
                            </span>
                          </label>
                        </div>

                        <div className="relative  rounded-bl-md rounded-br-md p-2 flex">
                          <div className="flex items-center h-5">
                            <input id="backend-option-praw" name="backend_option" type="radio" className="focus:ring-cyan-500 h-4 w-4 text-cyan-600 cursor-pointer border-gray-800 bg-gray-600"
                              checked={backend === 'praw'}
                              onChange={(e) => {
                                setBackend('praw');
                                setType('submissions')
                                setBeforeTimestamp('')
                              }}
                            />
                          </div>
                          <label htmlFor="backend-option-praw" className="ml-2 flex flex-col cursor-pointer">
                            <span className="block text-sm font-medium">
                              Reddit API
                            </span>
                            <span className="block text-xs opacity-75">
                              Realtime results. Fuzzy search. Better for "top" sorting. Submissions only.
                            </span>
                          </label>
                        </div>
                      </div>
                    </fieldset>
                  </React.Fragment>
                ) : ''}*/}
              </div>
            )}
            
				  </div>
				</form>
	    </div>

      <button type="submit" className="sm:hidden mt-4 block w-full py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
        // disabled={!keyword}
        disabled={!filtersApplied}
        onClick={(e) => submitForm(e)}
      >
        Search
      </button>
	  </div>
	)
};

export default Filters;

