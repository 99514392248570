import React, { useState, useEffect } from "react";
import {
  Link,
} from "react-router-dom";
import { withRouter } from "react-router";
import {
  TrendingUpIcon, InformationCircleIcon, QuestionMarkCircleIcon, ExclamationCircleIcon
} from '@heroicons/react/outline';
import ReactTooltip from 'react-tooltip';
import { Popover, Transition } from '@headlessui/react'

import { toast, ToastContainer } from 'react-toastify';
import copy from 'copy-to-clipboard';

import ErrorBoundary from "../common/errorboundary";
import SubredditTags from "../reddit/subreddittags";

import { readableNumber } from '../../utils/text';
// import { getParamsFromUrl, toQueryString } from '../../utils/urls';
import { trackEvent } from '../../utils/tracking';

import { searchesService } from '../../services/searches';
import { redditService } from '../../services/reddit';
// import { collectionsService } from '../../services/collections';


// renders a subreddit to a collection detail screen
const AudienceSubredditCard = ({
  subreddit,
  collectionItem,
  deleteCollectionItem,
  displayType,
  onMouseEnter,
  onMouseLeave,
}) => {
  // state
  // const views = ['details', 'topics', 'submissions'];
  const [showDescription, setShowDescription] = useState(false);
  const [terminology, setTerminology] = useState(false);
  const [loadingTerminology, setLoadingTerminology] = useState(true);
  const [submissions, setSubmissions] = useState(false);
  const [loadingSubmissions, setLoadingSubmissions] = useState(true);


  return (
    <Link to={`/audience/${collectionItem.collection_hash}/subs/${subreddit.name}/`}
      key={collectionItem.id} className={`group rounded-md shadow-lg bg-gray-800 hover:bg-gray-700 text-white  ${displayType === 'card' ? 'p-4' : 'p-3'}`}
      onMouseEnter={() => {
        if (onMouseEnter){
          onMouseEnter()
        }
      }}
      onMouseLeave={() => {
        if (onMouseLeave){
          onMouseLeave()
        }
      }}
    >
      <ErrorBoundary>
        <div className="flex items-center flex-1">
          {subreddit && subreddit.icon ? (
            <img className="h-12 w-12 mr-4 rounded-md" src={subreddit.icon} alt={subreddit.name} />
          ) : (
            <div className="h-12 w-12 mr-4 rounded-md bg-gray-700">
              <svg className="h-6 w-6 m-3 opacity-25" enableBackground="new 0 0 24 24" height="512" fill="currentColor" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg">
                <path d="m21.325 9.308c-.758 0-1.425.319-1.916.816-1.805-1.268-4.239-2.084-6.936-2.171l1.401-6.406 4.461 1.016c0 1.108.89 2.013 1.982 2.013 1.113 0 2.008-.929 2.008-2.038s-.889-2.038-2.007-2.038c-.779 0-1.451.477-1.786 1.129l-4.927-1.108c-.248-.067-.491.113-.557.365l-1.538 7.062c-2.676.113-5.084.928-6.895 2.197-.491-.518-1.184-.837-1.942-.837-2.812 0-3.733 3.829-1.158 5.138-.091.405-.132.837-.132 1.268 0 4.301 4.775 7.786 10.638 7.786 5.888 0 10.663-3.485 10.663-7.786 0-.431-.045-.883-.156-1.289 2.523-1.314 1.594-5.115-1.203-5.117zm-15.724 5.41c0-1.129.89-2.038 2.008-2.038 1.092 0 1.983.903 1.983 2.038 0 1.109-.89 2.013-1.983 2.013-1.113.005-2.008-.904-2.008-2.013zm10.839 4.798c-1.841 1.868-7.036 1.868-8.878 0-.203-.18-.203-.498 0-.703.177-.18.491-.18.668 0 1.406 1.463 6.07 1.488 7.537 0 .177-.18.491-.18.668 0 .207.206.207.524.005.703zm-.041-2.781c-1.092 0-1.982-.903-1.982-2.011 0-1.129.89-2.038 1.982-2.038 1.113 0 2.008.903 2.008 2.038-.005 1.103-.895 2.011-2.008 2.011z"/>
              </svg>
            </div>
          )}

          {subreddit ? (
            <h2 className="text-xl font-semibold">
              {/*<a href={subreddit.link} target="_blank" rel="noreferrer" className="cursor-pointer hover:underline"
                onClick={() => {
                  trackEvent("ExternalRedirect", {
                    "platform": "Reddit",
                    "type": "Subreddit",
                    "platform_id": subreddit.name,
                  });
                  searchesService.saveRedirect("RSR", subreddit.name, subreddit.name);
                }}
              >
                r/{ subreddit.name }
              </a>*/}
              <div
                // to={`/audience/${collectionItem.collection_hash}/subs/${subreddit.name}/`}
                // className="cursor-pointer hover:underline"
              >
                r/{ subreddit.name }
              </div>
              <div className="flex text-xs font-medium mt-1 ">
                <div className="opacity-50">
                  {readableNumber(subreddit.count_users)} members
                </div>

                <ReactTooltip id={`subreddit-stats-${subreddit.name}`} place="right" effect="solid" backgroundColor="white" textColor="black" />
                {subreddit.calculated && subreddit.calculated.growth_members_monthly != null ? (
                  <div className={`ml-2 flex ${subreddit.calculated.growth_members_monthly > 0 ? 'text-green-400' : subreddit.calculated.growth_members_monthly === 0 ? 'text-gray-400' : 'text-red-400'}`}>
                    <TrendingUpIcon className="h-4 w-4 mr-1" />
                    <div>{subreddit.calculated.growth_members_monthly.toFixed(1)}% / month</div>
                  </div>
                ) : subreddit.calculated && subreddit.calculated.growth_members_weekly != null ? (
                  <div className={`ml-2 flex text-gray-400`}>
                    <TrendingUpIcon className="h-4 w-4 mr-1" />
                    <div>{subreddit.calculated.growth_members_weekly.toFixed(2)}% / week</div>
                  </div>
                ) : subreddit.calculated && subreddit.calculated.growth_members_daily != null? (
                  <div className="text-gray-400 ml-2 flex">
                    <TrendingUpIcon className="h-4 w-4 mr-1" />
                    <div>{subreddit.calculated.growth_members_daily.toFixed(2)}% / day</div>
                    {/*<InformationCircleIcon className="h-4 w-4 ml-1 text-gray-500" 
                      data-for={`subreddit-stats-${subreddit.name}`} data-tip="No weekly growth data yet. Showing growth over the past day."
                    />*/}
                  </div>
                ) : !subreddit.active ? (
                  <div className="text-yellow-500 ml-2 flex">
                    <ExclamationCircleIcon className="h-4 w-4 mr-1" />
                    <div>Stale Data</div>
                  </div>
                ) : ''}
              </div>
            </h2>
          ) : (
            <h2 className="text-xl font-semibold">r/{subreddit.name}
              <div className="text-xs font-medium mt-1 opacity-50">
                Fetching Details...
              </div>
            </h2>
          )}

          <div className={`ml-auto flex items-center space-x-2 ${displayType === 'card' ? '' : 'flex-row-reverse'}`}>
            <Popover className="relative text-base">
              {({ open }) => (
                <>
                  <Popover.Button
                    className={`flex-shrink-0 inline-flex items-center justify-center py-2 ${displayType === 'card' ? '' : ''} focus:outline-none focus:ring-transparent focus:border-none rounded-lg ${open ? 'bg-gray-600 text-gray-200' : 'text-gray-400 hover:text-gray-200 hover:bg-gray-600'}`}
                  >
                    <span className="sr-only">More</span>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                      <path strokeLinecap="round" strokeLinejoin="round" d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z" />
                    </svg>
                  </Popover.Button>

                  <Transition
                    show={open}
                    as={React.Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Popover.Panel
                      static
                      className={`absolute z-10 top-0 w-36 px-2 sm:px-0 ${displayType === 'card' ? 'right-8' : 'left-8'}`}
                    >
                      <div className="rounded-lg shadow-lg ring-opacity-5 overflow-hidden text-gray-400">
                        <div className="relative grid gap-4 bg-white p-4">
                          <Link to={`/audience/${collectionItem.collection_hash}/subs/${subreddit.name}/`}
                            className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                              <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                              <path strokeLinecap="round" strokeLinejoin="round" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                            </svg>
                            <div className="ml-2">
                              <p className="font-medium text-gray-700">View</p>
                            </div>
                          </Link>

                          {subreddit.share_url ? (
                            <div className="-m-3 p-3 cursor-pointer flex items-start rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
                              onClick={(e) => {
                                e.preventDefault()
                                copy(subreddit.share_url);
                                toast.success("Copied public link to clipboard")
                              }}
                            >
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6 w-6">
                                  <path strokeLinecap="round" strokeLinejoin="round" d="M7.217 10.907a2.25 2.25 0 100 2.186m0-2.186c.18.324.283.696.283 1.093s-.103.77-.283 1.093m0-2.186l9.566-5.314m-9.566 7.5l9.566 5.314m0 0a2.25 2.25 0 103.935 2.186 2.25 2.25 0 00-3.935-2.186zm0-12.814a2.25 2.25 0 103.933-2.185 2.25 2.25 0 00-3.933 2.185z" />
                                </svg>
                              <div className="ml-2">
                                <p className="font-medium text-gray-700">Share</p>
                              </div>
                            </div>
                          ) : ''}

                          <div className="-m-3 p-3 cursor-pointer flex items-start rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
                            onClick={(e) => {
                              e.preventDefault()
                              deleteCollectionItem(collectionItem)
                            }}
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                              <path strokeLinecap="round" strokeLinejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                            </svg>
                            <div className="ml-2">
                              <p className="font-medium text-gray-700">Delete</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </>
              )}
            </Popover>
          </div>

        </div>

        {/*<nav className="flex mb-4">
          {views.map((v) => (
            <div key={v} onClick={() => setView(v)}
              className={`whitespace-nowrap flex-1 py-4 border-b-2 cursor-pointer text-center text-sm ${v === view ? 'border-cyan-500 text-cyan-500' : 'border-transparent text-gray-300 hover:text-gray-200 hover:border-gray-200'}`}
            >
              {v.split(' ').map((s) => s.charAt(0).toUpperCase() + s.substring(1)).join(' ')}
            </div>
          ))}
        </nav>*/}

        <div className={`flex 'h-44 flex-col overflow-auto pt-2`}>

          {/*<dt className="text-base font-normal opacity-50">
            Created
          </dt>
          <dd className="mt-1">
            <p className={`text-sm`}>
              { subreddit && subreddit.created }
            </p>
          </dd>*/}

          {subreddit && subreddit.tags ? (
            <div className={displayType === 'card' ? '' : 'hidden sm:block'}>
              {/*<dt className="mt-2 text-xs font-semibold text-gray-400 uppercase tracking-wide">
                Distinctive Features
              </dt>*/}
              <dd className="block">
                <SubredditTags subreddit={subreddit} tags={subreddit.tags} style={'link'} />
              </dd>
            </div>
          ) : ''}

          {subreddit && subreddit.description ? (
            <React.Fragment>
              <dt className="mt-4 text-xs font-semibold text-gray-400 uppercase tracking-wide">
                Description
              </dt>
              <dd className="mb-2">
                <p className={`text-sm opacity-100 overflow-ellipsis ${showDescription ? '' : 'cursor-pointer line-clamp-3'}`}
                  onClick={() => setShowDescription(true)}
                  // style={{minHeight: '70px'}}
                >
                  { subreddit && subreddit.description }
                </p>
              </dd>
            </React.Fragment>
          ) : ''}

          {/*<div className={'mt-auto pt-2'}>
            <Link className={displayType === 'card' ? '' : 'hidden sm:block'} to={`/audience/${collectionItem.collection_hash}/subs/${subreddit.name}/`}>
              <button type="button" className={`text-center flex items-center ${displayType === 'card' ? 'w-full' : 'ml-auto'} justify-center py-2 px-2 shadow-sm text-xs rounded-sm text-white hover:bg-cyan-600 focus:outline-none bg-gray-700`}>
                {displayType === 'card' ? 'Details & Insights' : 'View Details'}
              </button>
            </Link>
          </div>*/}

        </div>
      </ErrorBoundary>
    </Link>
  )
}

export default withRouter(AudienceSubredditCard);

