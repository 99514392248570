import React, { useState, useEffect } from "react"
import { connect } from 'react-redux';
import { Link } from "react-router-dom";

import { Helmet } from "react-helmet";
import ReactTooltip from 'react-tooltip';

import CheckMark from '@heroicons/react/solid/CheckIcon';

import Loader from "../../components/common/loader";
import FeaturePopover from '../common/featurePopover'

import { FEATURES } from '../common/constants.js';
import { PLANS, TRIAL_PRICES, TRIAL_LENGTH } from '../account/constants.js';

// import { profileService } from '../../services/profile';
import { paymentService } from '../../services/payment';
import { trackEvent } from '../../utils/tracking';
import { localStorageAvailable } from '../../utils/localStorage';


// shows paywall for a feature (most of the times served up by the container component FeaturePaywallWrapper)
const FeaturePaywall = ({ title, featureKey, className, currentUser }) => {
  const [checkingOut, setCheckingOut] = useState(false); 
  const [showPlanDetails, setShowPlanDetails] = useState(false); 
  const [showDayPassDetails, setShowDayPassDetails] = useState(false); 
  const [expandedFAQs, setExpandedFAQs] = useState([]); 

  // not guaranteed (if invalid key)
  var feature = FEATURES[featureKey];

  // override feature for Starter based on pricing test group
  if (feature && featureKey === 'pattern-finder' && ["has-ask", "has-patterns"].includes(currentUser.ab_tests['ai-on-starter'])){
    feature['orderType'] = 'starter';
  }
  if (feature && featureKey === 'ask' && currentUser.ab_tests['ai-on-starter'] === "has-ask"){
    feature['orderType'] = 'starter';
  }
  
  // not always guaranteed! need to set in common/constants.js
  const orderType = feature.orderType;
  const plan = orderType ? PLANS.filter(p => p.key === orderType)[0] : null;
  const eligibleForTrialSubscription = currentUser.trial.eligible_for_trial_subscription || false;
  var billingCycles = [
    {'key': 'monthly', 'title': 'Monthly', 'frequency': '/month', 'totalFrequency': '/mo'},
    {'key': 'yearly', 'title': 'Yearly', 'frequency': '/month', 'totalFrequency': '/yr', 'discount': ' - 17% off'},
  ];
  const [billingCycle, setBillingCycle] = useState(billingCycles[0]);


  useEffect(() => {
    // record that user sees the paywall
    trackEvent("PaywallView", {
      'feature': feature ? feature.title : "none",
      'plan': plan ? plan.key : "none",
      'currentPlan': currentUser.subscription ? currentUser.subscription.key : "none",
      "isNew": currentUser.identity.is_new ? "yes" : "no",
      "loginDays": currentUser.identity.login_days,
      "trial": eligibleForTrialSubscription,
    });

    if (localStorageAvailable()){
      localStorage.setItem('last_feature_paywall', feature ? feature.title : "none");
    }

    // send tracking event to LS
    // paymentService.paywallViewed({
    //   'feature': feature ? feature.title : "none",
    //   'plan': plan ? plan.key : "none",
    // })
  }, [])

  // takes user to the monthly subscription option
  const selectPlan = () => {
    if (!plan){
      return alert("Invalid subscription plan")
    }

    setCheckingOut(true);
    const affiliateReferrer = typeof(window.Rewardful) !== "undefined" ? window.Rewardful.referral : '';
    paymentService.initiateCheckout(
      {
          'product': plan.key,
          'billing_cycle': 'monthly',
          'affiliate_referrer': affiliateReferrer,
          'paywall': feature.title,
          "trial": eligibleForTrialSubscription,
          'feature': feature ? feature.title : "none",
      },
    (response) => {
      trackEvent("PaymentIntent", {
        'plan': plan.key,
        'billing_cycle': 'monthly',
        'feature': feature ? feature.title : "none",
      });
      if (response.data.redirect_url){
        // setCheckingOut(null); // don't need to do this as we go to stripe payment page
        window.location.href = response.data.redirect_url
      } else {
        alert("Missing stripe redirect url")
      }
    }, (error, response) => {
      setCheckingOut(null);
      alert("Error checking out")
    });
  }

  if (!feature){
    return '';  // if invalid key, don't show anything
  }

  var faqs = [
    {'key': 'cost', 'include': eligibleForTrialSubscription, 'question': "Why does a trial cost money?", 'answer': "There is a marginal cost because features of the trial incur AI & data processing costs."},
    {'key': 'cancel', 'include': eligibleForTrialSubscription, 'question': "Can I cancel if I don't like it?", 'answer': "You can cancel your subscription any time before the trial is up from your account settings, and not be charged for the month."},
    // {'key': 'reminder', 'question': "Will I get a reminder of trial expiration?", 'answer': "You will get an email a day before your trial expires."},
  ].filter(f => f.include)

  return (
    <div className={`${className} p-5 bg-gray-800 rounded-md border-white max-w-3xl`}>

      <Helmet><title>Upgrade | {feature.title || 'Unknown'}</title></Helmet>

      <div>
        <h2 className={`flex items-center font-bold justify-center`}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor"
            className="w-6 h-6 text-yellow-400 mr-2"
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z" />
          </svg>

          {title ? (
            <div>{title}</div>
          ) : (
            <div>Upgrade to use this feature</div>
          )}
        </h2>

        {/*<div>
          <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-cyan-100">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-cyan-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 21h7a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v11m0 5l4.879-4.879m0 0a3 3 0 104.243-4.242 3 3 0 00-4.243 4.242z" />
            </svg>
          </div>
        </div>*/}

        <div className="mt-4 bg-gray-700 rounded-md p-4">
          
          <div className="sm:flex sm:space-x-8">
            <div className="text-gray-200">
              <h3 className="font-bold text-lg">{feature.title}</h3>
              <div className="mt-2">{feature.description}</div>

              {feature.demo ? (
                <div className="py-2 sm:py-0"><a href={feature.demo} target="_blank" className="underline">More info</a></div>
              ) : ''}
            </div>

            {feature.video ? (
              <iframe width="100%" height="150" src={feature.video} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            ) : feature.gif ? (
              <iframe src={feature.gif} width="100%" height="150" allow="autoplay"></iframe>
            ) : ''}
          </div>
        </div>

        {/* <div className="mt-4">
          <div className="sm:flex sm:space-x-8 space-y-4 sm:space-y-0">
            <div className="flex-1">
              <h3 className="font-bold text-lg">GummySearch paid tiers</h3>
              <div className="">
                <div className="mt-2 text-gray-400 ">
                  Premium features are available via a day pass or monthly subscription.<br/><br/>
                  The day pass is great for those doing a quick research spike, or would like to test the Pro tier before committing to a subscription.
                </div>
              </div>
            </div>
          </div>
        </div> */}

        {plan ? (
          <div className="mt-4">

            <div className="sm:flex sm:space-x-8 space-y-4 sm:space-y-0">
              <div className="flex-1">
                <h3 className="font-bold text-lg">{feature.title || 'Feature'} is included in <span>GummySearch {plan.name}</span> plan</h3>
                  <div className="py-1 text-gray-400 flex items-center opacity-75 hover:opacity-100 cursor-pointer font-semibold" onClick={
                    () => setShowPlanDetails(!showPlanDetails)
                  }>
                    {showPlanDetails ? (
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                      </svg>
                    ) : (
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                      </svg>
                    )}
                    What else do I get with {plan.name}? 
                </div>
                {showPlanDetails ? (
                  <div className="">
                    <div className="mt-2 text-gray-200 font-normal">
                      {plan.description}
                    </div>
                    <div className="mt-2 text-gray-200">
                      <ul className="">
                        {plan.features.map(f => (
                          <li key={f.id} className={`flex items-center w-full mb-1 text-base`}>
                            <CheckMark className="w-5 h-5 mr-2 text-green-500" />
                            <span className="w-full text-gray-100">
                              <FeaturePopover id={f.id} label={f.label} />
                              {f.count ? (
                                <span className="ml-2 text-sm opacity-70">({f.count})</span>
                              ) : ''}
                            </span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                ) : ''}

                <div className="py-1 text-gray-400 flex items-center opacity-75 hover:opacity-100 cursor-pointer font-semibold" onClick={
                  () => setShowDayPassDetails(!showDayPassDetails)
                }>
                  {showDayPassDetails ? (
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                    </svg>
                  ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                    </svg>
                  )}

                  How does the Day Pass work?
                </div>
                {showDayPassDetails ? (
                  <div className="mt-2 text-gray-200 font-normal">
                    The Day Pass is a one-time payment of $10 that gives you access to the Pro tier for 24 hours (including 2000 AI minutes). The day pass is a great option for those doing a quick research spike, or would like to test the Pro tier before committing to a subscription.
                  </div>
                ) : ''}
              </div>


              {faqs.length ? (
                <div className="flex-1">
                  <h3 className="font-bold text-lg">FAQs</h3>

                  {faqs.map(f => (
                    <div key={f.key}>
                      <div className="py-1 text-gray-400 flex items-center opacity-75 hover:opacity-100 cursor-pointer font-semibold"
                        onClick={() => {
                          if (expandedFAQs.includes(f.key)){
                            setExpandedFAQs(expandedFAQs.filter(ef => ef !== f.key))
                          } else {
                            setExpandedFAQs([...expandedFAQs, f.key])
                          }
                        }}
                      >
                        {expandedFAQs.includes(f.key) ? (
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                          </svg>
                        ) : (
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                          </svg>
                        )}

                        {f.question}
                      </div>
                      {expandedFAQs.includes(f.key) ? (
                        <div className="ml-6 text-sm">{f.answer}</div>
                      ) : ''}
                    </div>
                  ))}
                  
                </div>
              ) : ''}
            </div>
          </div>
        ) : ''}

        <div className="mt-4">
          {currentUser && currentUser.subscription ? (
            <Link to={`/account/subscription/select/?plan=${plan.key}`} className="">
              <button className={`flex items-center cursor-pointer font-bold justify-center w-full py-2 rounded-md hover:opacity-90 border text-white bg-green-500 border-transparent`}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 mr-2">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 10.5V6.75a4.5 4.5 0 119 0v3.75M3.75 21.75h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H3.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z" />
                </svg>
                <div>Upgrade to {plan.name}</div>
              </button>
            </Link>
          ) : (
            <React.Fragment>
              {plan ? (
                <div className="flex items-center space-x-4">
                  <Link to="/account/subscription/day-pass/"
                    className={`items-center cursor-pointer font-bold justify-center w-full py-2 rounded-md hover:opacity-90 border text-white bg-cyan-500 border-transparent`}
                  >
                    <div className="flex items-center justify-center">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="hidden sm:block w-4 h-4 mr-2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 10.5V6.75a4.5 4.5 0 119 0v3.75M3.75 21.75h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H3.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z" />
                      </svg>
                      Buy Day Pass ($10)
                      
                    </div>
                  </Link>
                  <button className={`items-center cursor-pointer font-bold justify-center w-full py-2 rounded-md hover:opacity-90 border text-white bg-green-500 border-transparent`}
                    onClick={() => selectPlan()}
                    disabled={checkingOut}
                  >
                    <div className="flex items-center justify-center">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="hidden sm:block w-4 h-4 mr-2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 10.5V6.75a4.5 4.5 0 119 0v3.75M3.75 21.75h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H3.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z" />
                      </svg>
                      {eligibleForTrialSubscription ? (
                        <div>Try {plan.name} for {TRIAL_PRICES[plan.key]}</div>
                      ) : (
                        <div>Buy {plan.name} (${plan.price[billingCycle.key + 'Total']}{billingCycle['totalFrequency']})</div>
                      )}
                      
                    </div>
                    {eligibleForTrialSubscription ? (
                      <div className="text-xs text-center font-medium">{TRIAL_LENGTH} trial, then ${plan.price[billingCycle.key + 'Total']}{billingCycle['totalFrequency']}</div>
                    ) : ''}
                  </button>
                </div>
              ) : ''}
              
              {currentUser && currentUser.trial.day_passes ? (
                <div className="pt-4 text-sm text-center text-green-400">
                  <Link to="/account/subscription/" className="underline">Activate Purchased Day Pass ({currentUser.trial.day_passes})</Link>
                </div>
              ) : ''}
              
              <div className="pt-4 text-sm text-center">
                <Link to="/account/subscription/select/" className="underline opacity-60 hover:opacity-100">Compare all pricing plans</Link>
              </div>
            </React.Fragment>
          )}


          
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  // const {
  //   used, total, fetching,
  // } = state.trial;

  return {
    // 'trialTotal': total,
    // 'trialUsed': used,
    // 'trialFetching': fetching,
  }
};

const mapDispatchToProps = (dispatch) => {
  // const fetchTrial = (callback) => {
  //   dispatch(trialOperations.fetchTrial(callback))
  // };
  return {
    // fetchTrial,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FeaturePaywall);
