import React, { useEffect, useState, Fragment } from "react";
import { Helmet } from "react-helmet";
import { Dialog, Transition, Menu, Popover } from '@headlessui/react'
import {
  Switch,
  Route,
  Link,
  Redirect
} from "react-router-dom";
import { CSVLink } from "react-csv";

import Drawer from "../common/drawer";
import ErrorBar from "../common/errorBar";
import PropsRoute from '../common/propsroute';
import ErrorBoundary from "../common/errorboundary";
import NavBar from "../common/navbar";
import SelectPopover from "../common/selectPopover";

import SavedConversationsAll from "./savedconversationsall";
import SavedConversationsAnalyze from "./savedconversationsanalyze";
import Aggregates from "../../components/reddit/aggregates";

import { savedSearchesService } from '../../services/savedSearches';
import { redditService } from '../../services/reddit';
import { slugify } from '../../utils/text';
import { savedSearchOperations } from "../../redux/saved"

import { trackEvent } from '../../utils/tracking';
import { contains } from '../../utils/numbers';
import { getConversationCsvDict, applyFrontendFilters } from '../../utils/reddit';
import { toQueryString } from '../../utils/urls';


// right sidebar for a saved search result
// baseUrl is the url that this component stems from (it's used in multiple places)
const SavedConversationsDrawer = ({
  match,
  history,
  location,
  closeModal,
  currentUser,
  refetchAll,
}) => {
  const { savedSearchHash } = match.params; 
  const [savedSearch, setSavedSearch] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  
  const [downloadableResults, setDownloadableResults] = useState([]);  // set from child component
  const isProSubscriber = currentUser && currentUser.subscription && (currentUser.subscription.key === "pro");
  const isEffectiveProSubscriber = isProSubscriber || currentUser.trial.plan === 'pro';

  const [results, setResults] = useState([]); // combined submissions & comments
  const [fetching, setFetching] = useState(false); // just for the saved conversation & reddit IDs
  const [fetchingResults, setFetchingResults] = useState(false); // for actual conversation
  
  const [aggregates, setAggregates] = useState(null);
  const [fetchingAggregates, setFetchingAggregates] = useState(false);
  const [fetchingExtra, setFetchingExtra] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [frontendFilters, setFrontendFilters] = useState({});  // {key: [value1, value2]}

  useEffect(() => {
    fetchSavedSearch()
  }, [
    savedSearchHash,
  ]); // Run when saved search ID changes

  // effects
  useEffect(() => {
    if (savedSearch){
      fetchConversations();
    }
  }, [savedSearch]); // Go fetch all conversations when we get here

  useEffect(() => {
    prepareDownloadCSV();
  }, [results]); // Organize the CSV data when these change

  // actions
  const fetchSavedSearch = () => {
    setLoading(true)
    setError(null)
    savedSearchesService.getSavedSearch(match.params.savedSearchHash, (results) => {
      if (results && results.data){
        setSavedSearch(results.data)
        setLoading(false)
      }
    }, (response, error) => {
      setError(error)
      setLoading(false)
    })
  }

  const deleteSearch = () => {
    savedSearchesService.deleteSavedSearch(match.params.savedSearchHash, (results) => {
      // refetch
      if (refetchAll){
        refetchAll();
      }
    }, (response, error) => {
      setError(error)
      alert("Failed to delete")
    });

    closeModal();
  }

  const fetchConversations = () => {
    setFetching(true);
    setError(null);
    savedSearchesService.getManualConversations(savedSearch.hash, (response) => {
      const submissionIds = response.data.filter(r => r.conversation_type === "RSU").map(r => r.platform_id)
      const commentIds = response.data.filter(r => r.conversation_type === "RCO").map(r => r.platform_id);
      fetchCombinedResults([...submissionIds, ...commentIds])
      setFetching(false)
      
    }, (error, response) => {
      setFetching(false);
        if (error.response && error.response.status === 500){
          setError("Error fetching conversations.")
        } else {
          setError(response || "Error fetching conversations.")
        }
    })
  }

  const fetchCombinedResults = (ids) => {
    // fetches all results based on IDs
    // WATCH OUT! If there are clashes between comment & submission IDs, we might have some invalid convos coming up
    // this method assumes comment & submission IDs on reddit don't overlap, but they totally could
    // in that case, we might need to break out this call into 2 (specifying comments & submissions)

    // track the search
    const trackParams = {
      "platform": "Reddit",
      "saved_search": "true",
      "saved_search_manual": "true",
      "type": "reddit-all",
    }
    trackEvent("PerformConversationSearch", trackParams);

    setFetchingResults(true);
    setError(false);
    redditService.getConversations({"ids": ids, "type": "reddit-all", "backend": "praw"},
      (response) => {
        setResults(response.data.results);
        fetchExtras(response.data.results);
        fetchAggregates(ids);
        setFetchingResults(false);
      }, (error, response) => {
        setFetching(false);
        if (error.response && error.response.status === 500){
          setError("Error fetching results from Reddit.")
        } else {
          setError(response || "Error fetching results from Reddit.")
        }
      });
  }

  // gets extra data on this query, updates results
  const fetchExtras = (currentResults) => {
    if (!currentResults || !currentResults.length) return; // don't call extras if there's nothing to do
    const ids = currentResults.map(r => r.id);
    const allFilters = {"ids": ids, "type": "reddit-all", "backend": "praw"};
    setFetchingExtra(true);
    redditService.getConversationExtras(allFilters,
      (response) => {
        // update responses based on the IDs
        const updates = response.data;
        var newResults = currentResults.map((r) => {
          if (updates[r.id]){
            r = Object.assign(r, updates[r.id])
          }
          return r
        });
        setResults(newResults);
        setFetchingExtra(false);
      }, (error, response) => {
        setFetchingExtra(false);
        console.error("error fetching extras", response)
      });
  }

  // gets aggregate data on this query
  const fetchAggregates = (ids) => {
    if (!ids || !ids.length) return; // don't call aggregates if there's nothing to do
    const allFilters = {"ids": ids, "type": "reddit-all", "backend": "praw"};
    setFetchingAggregates(true);
    redditService.getConversationAggregates(allFilters,
      (response) => {
        setAggregates(response.data);
        setFetchingAggregates(false);
      }, (error, response) => {
        setFetchingAggregates(false);
        console.error("error fetching aggregates", response)
      });
  }


  const prepareDownloadCSV = () => {
    // gets the comments + submissions, and puts them into a format to be downloaded. passes to parent
    setDownloadableResults(getConversationCsvDict(results))
  }

  const clearFrontendFilters = () => {
    setFrontendFilters({})
  }

  // apply a frontend filter (or several) to limit display of existing results
  const applyFrontendFilter = (key, value) => {
    // if value is already applied in that filter, remove it. otherwise create/add
    var currentValue = frontendFilters[key] || [];
    if (contains(currentValue, value)){
      currentValue = currentValue.filter(v => JSON.stringify(v) != JSON.stringify(value))
    } else {
      currentValue.push(value)
    }

    const newFilters = Object.assign(frontendFilters, {[key]: currentValue});
    setFrontendFilters(Object.assign({}, newFilters));  // copy to ensure render happens
  }

  const clearFrontendFilter = (key) => {
    const newFilters = Object.assign(frontendFilters, {[key]: undefined});
    setFrontendFilters(Object.assign({}, newFilters));  // copy to ensure render happens
  }


  // state helpers
  const baseUrl = `/conversations/saved/${savedSearchHash}/`;

  // results & filtered results
  var filteredResults = applyFrontendFilters(results, frontendFilters);

  // counts & tabs
  const countResults = results && results.length;
  const countFilteredResults = filteredResults.length;
  const tabs = [
    { name: `${countResults ? (countFilteredResults !== countResults ? `${countFilteredResults} / ${countResults}` : countResults) : ''} Saved`, link: `${baseUrl}all/`},
    // { name: 'Filters', link: `${baseUrl}aggregate/`, disabled: !results || !results.length},
    { name: 'Patterns', link: `${baseUrl}patterns/`},
  ]

  // sort results
  // if (sortOption === "Upvotes"){
  //   filteredResults = filteredResults.sort((a,b) => (a.score < b.score) ? 1 : ((b.score < a.score) ? -1 : 0));
  // } else if (sortOption === "Comments"){
  //   filteredResults = filteredResults.sort((a,b) => (a.num_comments < b.num_comments) ? 1 : ((b.num_comments < a.num_comments) ? -1 : 0));
  // } else if (!sortOption || sortOption === "Recent"){
  //   // should be default
  //   filteredResults = filteredResults.sort((a,b) => (a.timestamp_utc < b.timestamp_utc) ? 1 : ((b.timestamp_utc < a.timestamp_utc) ? -1 : 0));
  // }

  // highlighted keyword
  // var keywordToHighlight = (selectedKeyword && selectedKeyword.value) || '';
  var keywordToHighlight = '';
  if (frontendFilters.categoryKeywords && frontendFilters.categoryKeywords.length){
    keywordToHighlight = `${frontendFilters.categoryKeywords.join(",")},${keywordToHighlight}`
  }
  if (frontendFilters.phrase && frontendFilters.phrase.length){
    keywordToHighlight = `${frontendFilters.phrase.join(",")},${keywordToHighlight}`
  }

  // used to jump to advanced search or explore comments
  const submissionIds = filteredResults.filter(r => r._meta.type === "reddit-submission").map(r => r.id);

  return (
    <Drawer close={closeModal}>
      <ErrorBoundary>
        <div className="flex flex-col h-full">
          <Helmet><title>Conversations | Saved</title></Helmet>

          <div className="p-4">
            <div className="flex items-start justify-between">
              <div className="text-lg font-medium flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10 mr-2" viewBox="0 0 24 24"
                  stroke={savedSearch ? savedSearch.color : '#FAFAFA'} fill={`${savedSearch ? savedSearch.color : '#FAFAFA'}75`}
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z" />
                </svg>
                {savedSearch ? (
                  <div className="">
                    <div>{savedSearch.name}</div>
                    <div className="text-xs opacity-50">{savedSearch.count_conversations} conversation{savedSearch.count_conversations === 1 ? '' : 's'}</div>
                  </div>
                ) : (
                  <div className="h-11"></div>
                )}
                
              </div>
              <div className="ml-3 h-7 flex items-center space-x-2">
                

                <Popover className="relative">
                  {({ open, close }) => (
                    <>
                      <Popover.Button
                        className={`rounded-md text-gray-400 hover:text-gray-300 focus:outline-none flex items-center ${open ? 'text-white' : ''}`}
                      >
                        
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z" />
                        </svg>
                        <span className="hidden sm:inline-block">More</span>
                      </Popover.Button>

                      <Transition
                        show={open}
                        as={React.Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                      >
                        <Popover.Panel
                          static
                          className="absolute z-10 right-12 top-0 px-2 w-screen max-w-xs sm:px-0"
                        >
                          <div className="rounded-lg shadow-lg ring-opacity-5 overflow-hidden">
                            <div className="relative grid gap-6 bg-white text-gray-900 p-4">

                              <div onClick={closeModal}
                                className="cursor-pointer -m-3 p-3 flex items-start rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
                              >
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                </svg>
                                <div className="ml-4">
                                  <p className="font-medium text-gray-700">Close</p>
                                </div>
                              </div>

                              {/*{submissionIds && submissionIds.length ? (
                                <Link to={`/reddit/${toQueryString({
                                  'ids': submissionIds,
                                  'type': 'submission-replies',
                                })}`}
                                  // disabled={!isEffectiveProSubscriber}
                                  className="cursor-pointer -m-3 p-3 flex items-start rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
                                >
                                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M10 21h7a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v11m0 5l4.879-4.879m0 0a3 3 0 104.243-4.242 3 3 0 00-4.243 4.242z" />
                                  </svg>
                                  <div className="ml-4 flex items-center">
                                    <p className="font-medium text-gray-700">Browse Comments</p>
                                    {!isEffectiveProSubscriber ? (
                                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="ml-2 w-5 h-5 text-yellow-400">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z" />
                                      </svg>
                                    ) : ''}
                                  </div>
                                </Link>
                              ) : ''}*/}
                              

                              <CSVLink
                                data={downloadableResults}
                                filename={savedSearch ? `${slugify(savedSearch.name)}.csv` : "gummysearch-download.csv"}
                                className="btn btn-primary"
                                target="_blank"
                                onClick={close}
                                disabled={!downloadableResults || !downloadableResults.length || !currentUser.features.download_csv}
                                className="cursor-pointer -m-3 p-3 flex items-start rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
                              >
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                                </svg>
                                <div className="ml-4 flex items-center">
                                  <p className="font-medium text-gray-700">Download</p>
                                  {!currentUser.features.download_csv ? (
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="ml-2 w-5 h-5 text-yellow-400">
                                      <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z" />
                                    </svg>
                                  ) : ''}
                                </div>
                              </CSVLink>
                              <div onClick={() => {
                                if (window.confirm("Are you sure you want to delete this list of conversations?")){
                                  deleteSearch()
                                }
                              }}
                                className="cursor-pointer -m-3 p-3 flex items-start rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
                              >
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                </svg>
                                <div className="ml-4">
                                  <p className="font-medium text-gray-700">Delete</p>
                                </div>
                              </div>
                              
                            </div>
                            
                          </div>
                        </Popover.Panel>
                      </Transition>
                    </>
                  )}
                </Popover>

                <button
                  className="rounded-md text-gray-400 hover:text-gray-300 focus:outline-none flex items-center space-x-1"
                  onClick={closeModal}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                  </svg>
                  <span className="hidden sm:inline-block">Close</span>
                </button>

              </div>
            </div>
          </div>

          <div className="px-4 flex items-center flex-wrap border-b border-solid border-gray-800">
            <NavBar tabs={tabs} />

            <div className="ml-auto">

              {/*{sortOptions ? (
                <div className="ml-4 flex items-center" disabled={loading}>
                  <div className="text-xs opacity-50">Sort</div>
                  <div className="ml-2">
                    <SelectPopover
                      options={sortOptions}
                      currentOption={sortOption}
                      setCurrentOption={setSortOption}
                      labelField={undefined}
                    />
                  </div>
                </div>
              ) : ''}*/}
              
            </div>
          </div>
          
          {savedSearch ? (
            <div id="search-results-content" className="flex-grow overflow-hidden flex flex-col">
              <Switch>
                <Redirect exact path={`${baseUrl}`} to={`/saved/${savedSearch.hash}/all/`} />
                <PropsRoute path={`${baseUrl}all/`} component={SavedConversationsAll}
                  savedSearch={savedSearch} currentUser={currentUser} baseUrl={baseUrl}
                  results={filteredResults} fetching={fetching || fetchingResults}
                  fetchConversations={fetchConversations}
                />
                <PropsRoute path={`${baseUrl}patterns/`} component={SavedConversationsAnalyze}
                  savedSearch={savedSearch} currentUser={currentUser} baseUrl={baseUrl}
                  results={filteredResults} fetching={fetching || fetchingResults}
                  // fetchConversations={fetchConversations}
                />

                {/*<Route path={`${baseUrl}aggregate/`}
                  render={(props) => {
                    return (
                      <div id="aggregates-pane" className="overflow-y-auto px-6 py-4" disabled={fetching}>
                        <Helmet><title>Conversations | Saved | Aggregate</title></Helmet>
                        <Aggregates
                          results={results}
                          aggregates={aggregates}
                          filteredResults={filteredResults}
                          frontendFilters={frontendFilters}
                          applyFrontendFilter={applyFrontendFilter}
                          clearFrontendFilter={clearFrontendFilter}
                          fetching={fetching}
                          fetchingExtra={fetchingExtra}
                          fetchingAggregates={fetchingAggregates}
                          hideTitle={true}
                          hideSections={['velocity_frequency']}
                          // keyword={params.keyword}
                          // velocity={results && results.velocity}
                          // allKeywords={results && results.keywords}
                        />
                      </div>
                    );
                  }}
                />*/}
              </Switch>
            </div>
          ) : ''}
          
          {error ? (
            <ErrorBar error={error} setError={setError} onRetry={fetchConversations}/>
          ) : ''}    
        </div>
      </ErrorBoundary>  
    </Drawer>
  );
}

export default SavedConversationsDrawer;

