import React, { useState, useEffect } from "react"
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";

import {
  Link, Switch, Route
} from "react-router-dom";
import { ModalRoute } from 'react-router-modal';
import Toggle from 'react-toggle'
// import { QuestionTo } from '@questionto/react';
import {
  CheckIcon, InformationCircleIcon, ViewListIcon, ViewBoardsIcon,
} from '@heroicons/react/outline'

import { collectionsService } from '../../services/collections';
import { searchesService } from '../../services/searches';
import { uiOperations } from "../../redux/ui"
// import { hasStarter } from "../../utils/account"

import DrawerRoute from "../common/drawerroute";
import ErrorBoundary from "../common/errorboundary";
import PropsRoute from "../common/propsroute";
import SelectPopover from "../common/selectPopover";
import AudienceTheme from "./audienceTheme";
// import AudienceSearch from "./audienceSearch";


const AudienceThemes = ({
  collection,
  suggestions,
  loadingSuggestions,
  setUiState,
  currentUser,
  history,
  match,
  topics,
  loadingTopics,
  subreddits,
}) => {
  // state
  const currentTheme = history.location.pathname && history.location.pathname.split('/themes/')[1] && history.location.pathname.split('/themes/')[1].replace('/', '')
  const isMobile = window.screen.width < 800;  // on mobile, show detail view in drawer

  useEffect(() => {
    if (suggestions && suggestions.length && !isMobile){
      // only redirect to first if there's no current theme on the page (and we're not on mobile)
      if (history.location.pathname && !currentTheme){
        history.push(`/audience/${collection.hash}/themes/${suggestions[0].slug}/`)
      }
    }
  }, [suggestions]);

  const categories = [
    {'title': 'Scoring-based themes', 'suggestions': suggestions.filter(s => !s.requiresStarter)},
    {'title': 'AI-based themes', 'suggestions': suggestions.filter(s => s.requiresStarter).sort((a,b) => (a.count < b.count) ? 1 : ((b.count < a.count) ? -1 : 0)), 'counted': true},
    // {'title': 'Other', 'suggestions': []}
  ]

  return (
    <ErrorBoundary>
      <Helmet><title>Audience | Themes</title></Helmet>

      <div className="mt-4 flex space-x-8">
        <div className="flex-1">
          
          {categories.map(c => (
            <div key={c.title} className="mb-4">
              <div className="flex items-center mb-2">
                <h2 className="text-lg font-medium flex items-center flex-wrap">
                  <span>{c.title}</span>
                  {/*<p className="ml-2 text-gray-500 truncate">
                    {loadingSuggestions ? (
                      <svg className="animate-spin h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                    ) : (
                      <span>{c.suggestions.length}</span>
                    )}
                  </p>*/}
                </h2>
              </div>

              {c.counted && collection.calculated && collection.calculated.current_members < 200000 ? (
                <div className="mx-auto p-2 px-3 text-semibold bg-gray-600 rounded-md mb-4 flex">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6 w-6 mr-2">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z" />
                  </svg>

                  Small audiences could have low number of theme results. <Link to={`/audience/${collection.hash}/add/`} className="underline ml-auto">
                    Add similar subreddits
                  </Link>
                </div>
              ) : ''}
              
              <div className="grid grid-cols-1 lg:grid-cols-2 bg-gray-700 rounded-md overflow-hidden gap-px">
                {c.suggestions.map(s => (
                  <Link key={s.slug} to={`/audience/${collection.hash}/themes/${s.slug}/`}
                    disabled={c.counted && !s.count}
                    className={`text-sm sm:text-base p-2 sm:p-4 flex sm:items-center cursor-pointer ${currentTheme == s.slug ? 'bg-gray-700' : 'bg-gray-800 hover:bg-gray-700'}`}
                  >
                    {s.icon ? (
                      <div className="mr-2 sm:mr-4 pt-2 sm:pt-0">
                        <s.icon className="h-6 w-6 sm:h-12 sm:w-12 opacity-50"/>
                      </div>
                    ) : ''}
                    <div>
                      <div className="flex items-center py-1 text-lg font-bold">
                        {s.name}
                        {/*{!hasStarter && s.requiresStarter ? (
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="ml-2 w-5 h-5 text-yellow-400">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z" />
                          </svg>
                        ) : ''}*/}
                      </div>
                      <div className="text-sm sm:text-base text-gray-400">
                        {s.count ? (
                          <span className="text-gray-200 font-bold mr-1">{s.count}</span>
                        ) : ''}
                        <span>{s.description}</span>
                      </div>
                    </div>
                  </Link>
                ))}
              </div>

              {/*{c.suggestions.length === 0 && c.title === 'Other' ? (
                <QuestionTo projectId="7mx2uar553l52t8" userId={currentUser && currentUser.identity.id}>
                  <div className="p-4 border-2 border-gray-800 border-dashed rounded-md hover:border-gray-700 cursor-pointer hover:bg-gray-800">
                    <div className="pointer-events-none">More themes coming soon!</div>
                    <div className="pointer-events-none">Have an idea? Click here to submit feedback.</div>
                  </div>
                </QuestionTo>
              ) : ''}*/}
            </div>
          ))}
        </div>



        {suggestions && suggestions.length ? (
          <React.Fragment>
            {!isMobile ? (
              <div id="themes-detail" className="flex-1">
                <PropsRoute path={`/audience/${collection.hash}/themes/:slug/`} component={AudienceTheme}
                  collection={collection} 
                  suggestions={suggestions} loadingSuggestions={loadingSuggestions}
                  subreddits={subreddits || []}
                  topics={topics} loadingTopics={loadingTopics}
                />
              </div>
            ) : (
              <Switch>
                <Route path={`/audience/${collection.hash}/themes/:slug/`}
                  render={(props) => {
                    if (loadingSuggestions) return ''; // just while loading
                    return (
                      <DrawerRoute closeUrl={`/audience/${collection.hash}/themes/`} {...props}>
                        <div id="topic-drawer" className="h-full flex flex-col bg-gray-900 text-white shadow-xl overflow-auto">
                          <div className="p-4 sm:p-6">
                            <AudienceTheme
                              collection={collection} 
                              suggestions={suggestions} loadingSuggestions={loadingSuggestions}
                              subreddits={subreddits || []}
                              topics={topics} loadingTopics={loadingTopics}

                              match={props.match} history={history}
                              onClose={() => history.push(`/audience/${collection.hash}/themes/`)}
                            />
                          </div>
                        </div>
                      </DrawerRoute>
                    );
                  }}
                />
              </Switch>
            )}
          </React.Fragment>
        ) : ''}

        
      </div>
    </ErrorBoundary>
  );
}

const mapStateToProps = state => {
  const {
    currentUser
  } = state.user;
  const {
    topicSort,
  } = state.ui;

  return {
    currentUser,
    topicSort,
  }
};

const mapDispatchToProps = (dispatch) => {
  const setUiState = (key, value) => {
    dispatch(uiOperations.setUiState(key, value))
  };
  return {
    setUiState,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AudienceThemes);


