import React, { useState } from "react";
import { connect } from 'react-redux';
import {
  Link,
} from "react-router-dom";
import Modal from "../common/modal";
import { Helmet } from "react-helmet";

import { InformationCircleIcon } from '@heroicons/react/outline'

import { collectionsService } from '../../services/collections';
import { audiencesOperations } from "../../redux/audiences"

import ErrorBar from "../common/errorBar";


const AudienceInfoModal = ({
  closeModal,
  collection,
  searchSuggestions,
  history
}) => {
  const [keyword, setKeyword] = useState('');

  const performSearch = (e) => {
    e.preventDefault()
    history.push(`/audience/${collection.hash}/search/?keyword=${encodeURIComponent(keyword)}`);
    setKeyword(''); // clear the form when we open the drawer
  }
  
  return (
    <Modal close={closeModal} widthClass={'max-w-3xl'}>
      <Helmet><title>Audience | Info</title></Helmet>
      
      <div className="flex items-center">
        <InformationCircleIcon className="h-6 w-6 opacity-50 mr-2"/>

        <h3 className="text-lg leading-6 font-medium">
          Welcome to your audience!
        </h3>

        <button className="focus:outline-none ml-auto" onClick={closeModal}>
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>

      <div className="text-md">
        <div className="mt-4">
          <div className="opacity-50 mb-1">About</div>
          <p>
            Congrats on making your first audience! An audience is simply a collection of Subreddits, but you can do some pretty amazing things from here.
          </p>
        </div> 

        <div className="mt-4">
          <div className="opacity-50 mb-1">Try your first search</div>
          <p className="">
            Search a keyword related to your business, a pain keyword like "I hate", or a popular topic or product category
          </p>

          <div className="">
            <div className="mt-2 relative text-gray-400 focus-within:text-white">
              <form onSubmit={performSearch}>
                <input id="keyword-search" type="text" placeholder="Keyword search in audience" autoComplete="off" spellCheck="off" autoFocus="on"
                  className="text-sm sm:text-base block bg-gray-900 w-full border-transparent p-4 pl-12 placeholder-gray-500 rounded-md focus:border-transparent focus:ring-0"
                  disabled={!collection}
                  value={keyword} onChange={(e) => {
                    setKeyword(e.target.value);
                  }}
                />
              </form>
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center justify-center pl-4">
                <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
                </svg>
              </div>
            </div>
            <div className="flex flex-wrap">
              {searchSuggestions.map(k => (
                <Link key={k} to={`/audience/${collection.hash}/search/?keyword=title:"${encodeURIComponent(k)}"`}
                  className={`mt-2 mr-2 py-1 px-2 text-base text-white border ${keyword === k ? 'bg-gray-700 border-gray-600' : 'hover:bg-gray-800 border-gray-700'} cursor-pointer rounded-md flex items-center`}
                  // onClick={(e) => {
                  //   // go to that search page
                  //   history.push(`/audience/${collection.hash}/search/?keyword=${encodeURIComponent(k)}`);
                  // }}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 mr-2 text-gray-400">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 18v-5.25m0 0a6.01 6.01 0 0 0 1.5-.189m-1.5.189a6.01 6.01 0 0 1-1.5-.189m3.75 7.478a12.06 12.06 0 0 1-4.5 0m3.75 2.383a14.406 14.406 0 0 1-3 0M14.25 18v-.192c0-.983.658-1.823 1.508-2.316a7.5 7.5 0 1 0-7.517 0c.85.493 1.509 1.333 1.509 2.316V18" />
                  </svg>

                  <div>{k}</div>
                </Link>
              ))}
            </div>
          </div>
        </div> 

        <div className="mt-4">
          <div className="opacity-50 mb-1">Other things to try</div>

          <div className="sm:flex space-x-4">
            <ul className="list-disc pl-6">
              <li>Set up a tracked keyword search to be notified when a keyword is mentioned</li>
              <li>Browse AI-tagged themes like Pain Points, Solution Requests, or Money Talk</li>
              <li>Browse popular topics based on volume & growth</li>
              <li>Explore product categories and product reviews</li>
              <li>Add suggested subreddits to your collection</li>
              <li>Save conversations to refer back to later</li>
              <li>Share your audience with friends or colleagues</li>
            </ul>
          </div>
        </div> 

        {/* <div className="mt-4">
          <div className="opacity-50 mb-1">Browse Customer Discovery Themes</div>

          <div className="sm:flex space-x-4">
            <div className="flex-1">
              <p className="">
                Click into the categories shown to quickly browse specific kinds of conversations happening in these Subreddits.
              </p>
              <p className="mt-2">
                Use the <span className="px-1.5 py-1 rounded-md relative top-0.5 bg-gray-700 inline-block">
                  <div className="flex items-center text-sm">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-2" fill="rgba(8, 145, 178,0.15)" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                      <path strokeLinecap="round" strokeLinejoin="round" d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z" />
                    </svg> Save Conversation
                  </div>
                </span> button to save interesting posts to refer back to later. This is useful if you are exploring multiple business ideas, or want to make an outreach list.
              </p>
            </div>
            <div className="flex-1 mt-2 sm:mt-0">
              <iframe width="100%" height="180px" src="https://www.youtube.com/embed/bxLdW8-WaAE" title="YouTube video player" frameborder="0" allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </div>
          </div>
        </div> 

        <div className="mt-4">
          <div className="opacity-50 mb-1">Search & Track Keywords</div>

          <div className="sm:flex space-x-4">
            <div className="flex-1">
              <p className="">
                You can search any keyword within these Subreddits!
              </p>
              <p className="mt-2">
                Use the <span className="px-1.5 py-1 rounded-md relative top-0.5 bg-gray-700 inline-block">
                  <div className="flex items-center text-sm">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-2" fill="rgba(8, 145, 178,0.15)" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                      <path strokeLinecap="round" strokeLinejoin="round" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
                    </svg> Track
                  </div>
                </span> button to be notified of future conversations that match this search.
              </p>
              <p className="mt-2">
                Check out <a className="underline cursor-pointer" href="https://gummysearch.com/insights/keywords-to-track-on-reddit/" target="_blank">this blog post</a> for examples of keywords to track if you are using Reddit for customer acquisition.
              </p>
            </div>
            <div className="flex-1 mt-2 sm:mt-0">
              <iframe width="100%" height="180px" src="https://www.youtube.com/embed/GkSXLMV9CLI" title="YouTube video player" frameborder="0" allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </div>
          </div>
        </div>  */}

        {/*<div className="mt-4">
          <div className="opacity-50 mb-1">Explore Subreddits Independently</div>
          <p>
            ...
          </p>
        </div> */}
      </div>

    </Modal>
  );
};


export default AudienceInfoModal;



