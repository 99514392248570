import React, { useState, useEffect } from "react"
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";

import {
  Link, Switch, Route
} from "react-router-dom";
import { ModalRoute } from 'react-router-modal';
import Toggle from 'react-toggle'
import {
  CheckIcon, InformationCircleIcon, ViewListIcon, ViewBoardsIcon, TrendingUpIcon, TrendingDownIcon, ExclamationCircleIcon,
} from '@heroicons/react/outline'

import { collectionsService } from '../../services/collections';
import { searchesService } from '../../services/searches';
import { uiOperations } from "../../redux/ui"
// import { hasStarter } from "../../utils/account"

import DrawerRoute from "../common/drawerroute";
import ErrorBoundary from "../common/errorboundary";
import PropsRoute from "../common/propsroute";
import SelectPopover from "../common/selectPopover";
import AudienceTopicCard from "./audienceTopicCard";
import AudienceTopic from "./audienceTopic";
import AudienceSearch from "./audienceSearch";

import { velocityToString, capitalizeFirst, readableNumber } from '../../utils/text';


const AudienceHome = ({
  collection,
  subreddits,
  loadingSubreddits,
  topics,
  loadingTopics,
  themes,
  loadingThemes,
  searchSuggestions,
  // setUiState,
  currentUser,
  // topicSort,
  history,
  match,
}) => {
  const isMobile = window.screen.width < 800;  // on mobile, show detail view in drawer
  const countItemsToShow = isMobile ? 3 : 6; // applies to subreddits, topics, and themes

  return (
    <ErrorBoundary>
      <Helmet><title>Audience | Home</title></Helmet>
      
      <div className="mt-4">
        <AudienceSearch collection={collection} searchSuggestions={searchSuggestions} />
      </div>

      <div className="mt-8 lg:flex lg:space-x-8 space-y-8 lg:space-y-0">
        <div className="flex-1">
          <div className="flex items-center mb-2">
            <h2 className="text-lg font-medium flex items-center flex-wrap">
              <span>Subreddits</span>

              <p className="ml-2 text-gray-500 truncate">
                {loadingSubreddits ? (
                  <svg className="animate-spin h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                ) : (
                  <span>{subreddits.length}</span>
                )}
              </p>
            </h2>

            <div className="ml-auto">
              <Link to={`/audience/${collection.hash}/add/`} className="mx-2 text-sm text-gray-500 hover:text-gray-300">
                + Add
              </Link>
            </div>
          </div>

          <div className="mt-2 bg-gray-800 rounded-md overflow-hidden">
            {subreddits
              .sort((a,b) => (a.item.count_users < b.item.count_users) ? 1 : ((b.item.count_users < a.item.count_users) ? -1 : 0))
              .filter((s, i) => i < countItemsToShow)
              .map(s => 
            (
              <Link to={`/audience/${collection.hash}/subs/${s.item.name}/`} 
                key={s.item.name} className="flex p-2 hover:bg-gray-700 border-b border-gray-700 last:border-transparent hover:border-gray-600 group"
              >
                {s.item && s.item.icon ? (
                  <img className="h-12 w-12 mr-4 rounded-md" src={s.item.icon} alt={s.item.name} />
                ) : (
                  <div className="h-12 w-12 mr-4 rounded-md bg-gray-700 group-hover:bg-gray-600">
                    <svg className="h-6 w-6 m-3 opacity-25" enableBackground="new 0 0 24 24" height="512" fill="currentColor" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg">
                      <path d="m21.325 9.308c-.758 0-1.425.319-1.916.816-1.805-1.268-4.239-2.084-6.936-2.171l1.401-6.406 4.461 1.016c0 1.108.89 2.013 1.982 2.013 1.113 0 2.008-.929 2.008-2.038s-.889-2.038-2.007-2.038c-.779 0-1.451.477-1.786 1.129l-4.927-1.108c-.248-.067-.491.113-.557.365l-1.538 7.062c-2.676.113-5.084.928-6.895 2.197-.491-.518-1.184-.837-1.942-.837-2.812 0-3.733 3.829-1.158 5.138-.091.405-.132.837-.132 1.268 0 4.301 4.775 7.786 10.638 7.786 5.888 0 10.663-3.485 10.663-7.786 0-.431-.045-.883-.156-1.289 2.523-1.314 1.594-5.115-1.203-5.117zm-15.724 5.41c0-1.129.89-2.038 2.008-2.038 1.092 0 1.983.903 1.983 2.038 0 1.109-.89 2.013-1.983 2.013-1.113.005-2.008-.904-2.008-2.013zm10.839 4.798c-1.841 1.868-7.036 1.868-8.878 0-.203-.18-.203-.498 0-.703.177-.18.491-.18.668 0 1.406 1.463 6.07 1.488 7.537 0 .177-.18.491-.18.668 0 .207.206.207.524.005.703zm-.041-2.781c-1.092 0-1.982-.903-1.982-2.011 0-1.129.89-2.038 1.982-2.038 1.113 0 2.008.903 2.008 2.038-.005 1.103-.895 2.011-2.008 2.011z"/>
                    </svg>
                  </div>
                )}
                <div className="mr-2">
                  <h3 className="text-lg leading-6 font-medium">
                    r/{s.item.name}
                  </h3>
                  <div className="text-gray-400 text-xs mt-1 flex items-center">
                    <span className="text-gray-200 bold mr-1">{readableNumber(s.item.count_users)}</span> members
                    {s.item.calculated && s.item.calculated.growth_members_monthly != null ? (
                      <span className={`ml-2 flex ${s.item.calculated.growth_members_monthly > 0 ? 'text-green-400' : s.item.calculated.growth_members_monthly === 0 ? 'text-gray-400' : 'text-red-400'}`}>
                        <TrendingUpIcon className="h-4 w-4 mr-1" />
                        <div>{s.item.calculated.growth_members_monthly.toFixed(1)}% / month</div>
                      </span>
                    ) : !s.item.active ? (
                      <div className="text-yellow-500 ml-2 flex">
                        <ExclamationCircleIcon className="h-4 w-4 mr-1" />
                        <div>Stale Data</div>
                      </div>
                    ) : ''}
                  </div>
                </div>
              </Link>
            ))}

            {subreddits.length > countItemsToShow ? (
              <Link to={`/audience/${collection.hash}/subs/`}
                className="opacity-50 hover:opacity-75 cursor-pointer text-center p-2 width-full block"
              >
                + {subreddits.length - countItemsToShow} more
              </Link>
            ) : subreddits.length === 0 && !loadingSubreddits ? (
              <Link to={`/audience/${collection.hash}/add/discover/`}
                className="opacity-50 hover:opacity-75 cursor-pointer text-center p-2 width-full block"
              >
                None yet, add more subreddits
              </Link>
            ): ''}
          </div>
        </div>

        <div className="flex-1">
          <div className="flex items-center mb-2">
            <h2 className="text-lg font-medium flex items-center flex-wrap">
              <span>Themes</span>

              <p className="ml-2 text-gray-500 truncate">
                {loadingThemes ? (
                  <svg className="animate-spin h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                ) : (
                  <span>{themes.length}</span>
                )}
              </p>
            </h2>
          </div>
          <div className="mt-2 bg-gray-800 rounded-md overflow-hidden">
            {themes.sort((a,b) => (a.count < b.count) ? 1 : ((b.count < a.count) ? -1 : 0)).filter((t, i) => i < countItemsToShow).map(t => (
              <Link to={`/audience/${collection.hash}/themes/${t.slug}/`} 
                key={t.slug} className="flex p-2 group hover:bg-gray-700 border-b border-gray-700 last:border-transparent hover:border-gray-600"
              >
                {t.icon ? (
                  <div className="h-12 w-12 mr-4 rounded-md bg-gray-700 group-hover:bg-gray-600">
                    <t.icon className="h-6 w-6 m-3 opacity-50"/>
                  </div>
                ) : ''}
                <div className="mr-2">
                  <h3 className="text-lg leading-6 font-medium">
                    {t.name}
                    {/*<span className="ml-2 font-bold text-sm opacity-50">{t.count}</span>*/}
                  </h3>
                  <div className="text-gray-400 text-xs mt-1 flex items-center">
                    {t.count ? (
                      <span className="text-gray-200 bold mr-1">{t.count}</span>
                    ) : ''}
                    {t.description}
                  </div>
                  
                </div>
              </Link>
            ))}

            {themes.length > countItemsToShow ? (
              <Link to={`/audience/${collection.hash}/themes/`}
                className="opacity-50 hover:opacity-75 cursor-pointer text-center p-2 width-full block"
              >
                + {themes.length - countItemsToShow} more
              </Link>
            ) : themes.length === 0 && !loadingThemes ? (
              <Link to={`/audience/${collection.hash}/add/discover/`}
                className="opacity-50 hover:opacity-75 cursor-pointer text-center p-2 width-full block"
              >
                None yet, add more subreddits
              </Link>
            ): ''}
          </div>
        </div>

        <div className="flex-1">
          <div className="flex items-center mb-2">
            <h2 className="text-lg font-medium flex items-center flex-wrap">
              <span>Topics</span>

              <p className="ml-2 text-gray-500 truncate">
                {loadingTopics ? (
                  <svg className="animate-spin h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                ) : (
                  <span>{topics.length}</span>
                )}
              </p>
            </h2>
          </div>
          <div className="mt-2 bg-gray-800 rounded-md overflow-hidden">
            {topics.sort((a,b) => (a.count < b.count) ? 1 : ((b.count < a.count) ? -1 : 0)).filter((t, i) => i < countItemsToShow).filter((t, i) => i < countItemsToShow).map(t => (
              <Link to={`/audience/${collection.hash}/topics/${encodeURIComponent(t.topic)}/`} 
                key={t.topic} className="flex p-2 group hover:bg-gray-700 border-b border-gray-700 last:border-transparent hover:border-gray-600"
              >
                <div className="h-12 w-12 mr-4 rounded-md bg-gray-700 group-hover:bg-gray-600">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-6 w-6 m-3 opacity-25">
                    <path fillRule="evenodd" d="M5.25 2.25a3 3 0 00-3 3v4.318a3 3 0 00.879 2.121l9.58 9.581c.92.92 2.39 1.186 3.548.428a18.849 18.849 0 005.441-5.44c.758-1.16.492-2.629-.428-3.548l-9.58-9.581a3 3 0 00-2.122-.879H5.25zM6.375 7.5a1.125 1.125 0 100-2.25 1.125 1.125 0 000 2.25z" clipRule="evenodd" />
                  </svg>

                </div>
                <div className="mr-2 w-full">
                  <h3 className="text-lg leading-6 font-medium">
                    {capitalizeFirst(t.topic)}
                  </h3>
                  
                  <div className="text-gray-400 text-xs flex">
                    <div className="w-full flex-grow bg-transparent rounded-sm relative flex-shrink-0 h-5 mr-4 mt-1">
                      {/*<div className="absolute bg-gray-800 group-hover:bg-gray-700 rounded-sm p-0.5 h-full" style={{width: parseInt((100 * t.stats.velocity_current) / topics[0].stats.velocity_current) + '%'}}></div>*/}
                      <div className="absolute text-gray-400 p-0.5 text-white max-w-full whitespace-nowrap overflow-ellipsis flex-shrink-0 truncate flex items-center">
                        <span className="text-gray-200 mr-1">{t.count}</span> post{t.count === 1 ? 's' : ''} about <span className="text-gray-200 ml-1">{capitalizeFirst(t.topic)}</span>

                        {typeof t.stats.growth_past === "number" ? (
                          <span className={`ml-2 text-xs flex items-center ${t.stats.growth_past > 0 ? 'text-green-400' : t.stats.growth_past === 0 ? 'text-gray-400' : 'text-red-400'}`}
                            data-for={`topic-stats-${t.topic}`} data-tip={`${t.stats.growth_past}% ${t.stats.growth_past > 0 ? 'increase' : 'decrease'} in mentions recently`}
                          >
                            {t.stats.growth_past > 0 ? (
                              <TrendingUpIcon className="h-4 w-4 mr-1" />
                            ) : (
                              <TrendingDownIcon className="h-4 w-4 mr-1" />
                            )}
                            <div>{t.stats.growth_past}%</div>
                          </span>
                        ) : ''}
                      </div>
                    </div>
                  </div>
                  
                  
                </div>
              </Link>
            ))}

            {topics.length > countItemsToShow ? (
              <Link to={`/audience/${collection.hash}/topics/`}
                className="opacity-50 hover:opacity-75 cursor-pointer text-center p-2 width-full block"
              >
                + {topics.length - countItemsToShow} more
              </Link>
            ) : topics.length === 0 && !loadingTopics ? (
              <Link to={`/audience/${collection.hash}/add/discover/`}
                className="opacity-50 hover:opacity-75 cursor-pointer text-center p-2 width-full block"
              >
                None yet, add more subreddits
              </Link>
            ): ''}
          </div>
        </div>
          
        
      </div>
    </ErrorBoundary>
  );
}

const mapStateToProps = state => {
  const {
    currentUser
  } = state.user;
  const {
    topicSort,
  } = state.ui;

  return {
    currentUser,
    topicSort,
  }
};

const mapDispatchToProps = (dispatch) => {
  const setUiState = (key, value) => {
    dispatch(uiOperations.setUiState(key, value))
  };
  return {
    setUiState,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AudienceHome);

