import BriefcaseIcon from '@heroicons/react/solid/BriefcaseIcon';
import UserGroupIcon from '@heroicons/react/solid/UserGroupIcon';
import EmojiHappyIcon from '@heroicons/react/solid/EmojiHappyIcon';
import GiftIcon from '@heroicons/react/solid/GiftIcon';
// TODO: find icon for free

// current plans via stripe
// const TRIAL_FEE = '$1'
const TRIAL_PRICES = {
  'free': '$0',
  'starter': '$1',
  'pro': '$7',
}
const TRIAL_LENGTH = '7 days'
const PLANS = [
    // {
    //   'name': 'Free',
    //   'key': 'free',
    //   'description': 'Basic free tier to quickly discover communities for your target niche and see what they discuss. Limited to 50 keyword searches.',
    //   // 'description': 'A free plan to start discovering your target audience and what they discuss on Reddit. Limited to 50 keyword searches.',
    //   'icon': GiftIcon,
    //   'price': {
    //       'monthly': 0,
    //       'monthlyTotal': 0,
    //       // 'monthlyUpcoming': 29,
    //       'yearly': 0,//15.75,
    //       'yearlyTotal': 0,
    //       // 'yearlyUpcoming': 24,
    //       'lifetime': 0,
    //       'lifetimeTotal': 0,
    //   },
    //   'index': 1,
    //   'features': [
    //     {'id': 'discover', 'label': 'Discover communities'},
    //     {'id': 'audiences', 'label': 'Segment audiences', 'count': '5'},
    //     {'id': 'audience-search', 'label': 'Keyword search', 'count': '50'},
    //   ],
    // },
    {
      'name': 'Starter',
      'key': 'starter',
      'description': 'The most powerful search engine for Reddit. Find communities, search them efficiently, and track relevant keywords to your business.',
      // 'description': 'Leverage Reddit to ideate startups, validate solutions, research markets, and find customers.',
      'icon': EmojiHappyIcon,
      'price': {
          'monthly': 29,
          'monthlyTotal': 29,
          // 'monthlyUpcoming': 29,
          'yearly': 19,
          'yearlyTotal': 232,
          // 'yearlyUpcoming': 24,
          'lifetime': 189,
          'lifetimeTotal': 189,
      },
      'index': 1,
      'features': [
        {'id': 'discover', 'label': 'Discover communities'},
        {'id': 'audiences', 'label': 'Segment audiences', 'count': 10},
        {'id': 'audience-search', 'label': 'Keyword search', 'count': '∞'},
        {'id': 'keyword-tracking', 'label': 'Tracked keyword alerts', 'count': 10},
        {'id': 'suggestions', 'label': 'Customer development themes'},
        {'id': 'trending-subreddits', 'label': 'Trending subreddits'},
      ],
    },
    {
      'name': 'Pro',
      'key': 'pro',
      // 'description': 'Explore Reddit like a pro. Be the first to discover growing niches, know what content performs best, save hours daily with meaningful insights.',
      'description': 'Includes powerful AI features like pattern-finding in hundreds of Reddit posts at once, Q&A LLM with your audience, and advanced subreddit reports.',
      'popular': true,
      'icon': BriefcaseIcon,
      'price': {
          'monthly': 59,
          'monthlyTotal': 59,
          // 'monthlyUpcoming': 59,
          'yearly': 39,
          'yearlyTotal': 472,
          // 'yearlyUpcoming': 49,
          'lifetime': 389,
          'lifetimeTotal': 389,
      },
      'index': 2,
      'features': [
        {'id': 'discover', 'label': 'Discover communities'},
        {'id': 'audiences', 'label': 'Segment audiences', 'count': 25},
        {'id': 'audience-search', 'label': 'Keyword search', 'count': '∞'},
        {'id': 'keyword-tracking', 'label': 'Tracked keyword alerts', 'count': 25},
        {'id': 'suggestions', 'label': 'Customer development themes'},
        {'id': 'trending-subreddits', 'label': 'Exploding subreddits'},

        // new
        {'id': 'pattern-finder', 'label': 'Find patterns w/AI', 'count': '5k AI minutes'}, // AB test putting this one in starter
        {'id': 'ask', 'label': 'Q&A interface w/AI'}, // AB test putting this one in starter
        {'id': 'reddit-search', 'label': 'Advanced search UI'},
        {'id': 'subreddit-performance', 'label': 'Content promotion insights'},
        {'id': 'subreddit-influencers', 'label': 'Subreddit influencers'},
        {'id': 'notification-channels', 'label': 'Slack/Discord integration'},
        {'id': 'products', 'label': 'Product Reviews'},
        {'id': 'csv-export', 'label': 'CSV exports'},
      ],
    },
    {
      'name': 'Mega',
      'key': 'mega',
      'description': 'For those who analyze Reddit at scale, such as customer research firms, startup studios, or content agencies. Comes with 20k AI minutes.',
      'popular': true,
      'icon': UserGroupIcon,
      'price': {
          'monthly': 199,
          'monthlyTotal': 199,
          // 'monthlyUpcoming': 199, // not being used?
          'yearly': 133,
          'yearlyTotal': 1599,
          // 'yearlyUpcoming': 131, // not being used?
          'lifetime': 989,
          'lifetimeTotal': 989,
      },
      'index': 3,
      'features': [
        {'id': 'discover', 'label': 'Discover communities'},
        {'id': 'audiences', 'label': 'Segment audiences', 'count': 100},
        {'id': 'audience-search', 'label': 'Keyword search', 'count': '∞'},
        // {'id': 'subreddit-insights', 'label': 'Subreddit insights'},
        {'id': 'keyword-tracking', 'label': 'Tracked keyword alerts', 'count': 100},
        {'id': 'suggestions', 'label': 'Customer development themes'},
        {'id': 'trending-subreddits', 'label': 'Exploding subreddits'},
        {'id': 'pattern-finder', 'label': 'Find patterns w/AI', 'count': '20k AI minutes'},
        {'id': 'ask', 'label': 'Q&A interface w/AI'},
        {'id': 'reddit-search', 'label': 'Advanced search UI'},
        {'id': 'subreddit-performance', 'label': 'Content promotion insights'},
        {'id': 'subreddit-influencers', 'label': 'Subreddit influencers'},
        {'id': 'notification-channels', 'label': 'Slack/Discord integration'},
        {'id': 'products', 'label': 'Product Reviews'},
        {'id': 'csv-export', 'label': 'CSV exports'},

        // new
        // {'id': 'multiple-seats', 'label': 'Multiple user seats', 'count': 3},
        // {'id': 'shareable-reports', 'label': 'Shareable reports'},
      ],
    },
  ];

// for limit packs (maybe use this in PLANS later?)
const RESOURCES = [
  {
    'id': 'AI',
    'name': 'AI Minutes',
    'timeframe': '/mo',
    // 'cost': '$10/mo per 1k AI minutes',

    'allowances': {
      'starter': 0,
      'pro': 5000,
      'mega': 20000,
    },
    'options': {
      'monthly': [
        {'quantity': 0, 'costAdditional': 0},
        {'quantity': 1000, 'costAdditional': 10},
        {'quantity': 5000, 'costAdditional': 45},
        {'quantity': 10000, 'costAdditional': 80},
      ],
      'yearly': [
        {'quantity': 0, 'costAdditional': 0},
        {'quantity': 1000, 'costAdditional': 100},
        {'quantity': 5000, 'costAdditional': 450},
        {'quantity': 10000, 'costAdditional': 800},
      ]
    }
  },
  {
    'id': 'AU',
    'name': 'Audiences',
    'allowances': {
      'starter': 10,
      'pro': 25,
      'mega': 100,
    },
    'options': {
      'monthly': [
        {'quantity': 0, 'costAdditional': 0},
        {'quantity': 5, 'costAdditional': 10},
        {'quantity': 25, 'costAdditional': 40},
        {'quantity': 75, 'costAdditional': 90},
      ],
      'yearly': [
        {'quantity': 0, 'costAdditional': 0},
        {'quantity': 5, 'costAdditional': 100},
        {'quantity': 25, 'costAdditional': 400},
        {'quantity': 75, 'costAdditional': 900},
      ]
    }
  },
  {
    'id': 'TR',
    'name': 'Tracked Keywords',
    'allowances': {
      'starter': 10,
      'pro': 25,
      'mega': 100,
    },
    'options': {
      'monthly': [
        {'quantity': 0, 'costAdditional': 0},
        {'quantity': 10, 'costAdditional': 20},
        {'quantity': 50, 'costAdditional': 90},
        {'quantity': 100, 'costAdditional': 160},
      ],
      'yearly': [
        {'quantity': 0, 'costAdditional': 0},
        {'quantity': 10, 'costAdditional': 200},
        {'quantity': 50, 'costAdditional': 900},
        {'quantity': 100, 'costAdditional': 1600},
      ]
    }
  },
]


export {
    PLANS,
    TRIAL_PRICES,
    TRIAL_LENGTH,
    RESOURCES,
}
