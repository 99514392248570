import React, { useState, useRef, useEffect, Fragment } from "react";
import { connect } from 'react-redux';
import { PlusIcon } from '@heroicons/react/solid'
import { useHistory } from "react-router-dom";
import { Transition } from '@headlessui/react'
import { Link } from "react-router-dom";

import { collectionsService } from '../../services/collections';
import { audiencesOperations } from "../../redux/audiences"

import ErrorBar from "../common/errorBar";
import LoaderFancy from "../common/loaderfancy";
import PublicAudienceCard from "./publicaudiencecard";
import PublicAudiences from "./publicaudiences";

import { toQueryString } from '../../utils/urls';


const NameAudience = ({
  showHeader,
  showIcons,
  noPublicScroll, // don't show scrollbar on the public collections
}) => {
  // state
  const history = useHistory();
  const nameInput = useRef(null);
  const [name, setName] = useState('');
  const [missingName, setMissingName] = useState(false);
  const isMobile = window.screen.width < 600;  // no hovering on mobile, just clicks
  const [publicCollections, setPublicCollections] = useState([]);
  const [fetchingPublicCollections, setFetchingPublicCollections] = useState(true);
  const [showCollections, setShowCollections] = useState(false);
  // const [error, setError] = useState(null);
  const [creating, setCreating] = useState(null);


  // effects
  useEffect(() => {
    // Autofocus if we're on web
    const isMobile = window.screen.width < 600;
    if (!isMobile && nameInput && nameInput.current){
      nameInput.current.focus()
    }
  }, []);

  // actions

  const nameAudience = (e) => {
    if (e){
      e.preventDefault();  // to stop page refresh
    }

    if (!name){
      setMissingName(true);
      nameInput.current.focus();
      return;
    }

    history.push(`/audiences/new/discover/?name=${encodeURIComponent(name)}&keywords=${encodeURIComponent(name)}`)
  } 

  return (
    <div className="w-full mx-auto">
      {showHeader ? (
        <div className="text-center mb-4">
          <div className="inline-block mx-auto p-5 rounded-md bg-gray-700">
            <svg
              className="mx-auto h-12 w-12 text-gray-400"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 48 48"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M34 40h10v-4a6 6 0 00-10.712-3.714M34 40H14m20 0v-4a9.971 9.971 0 00-.712-3.714M14 40H4v-4a6 6 0 0110.713-3.714M14 40v-4c0-1.313.253-2.566.713-3.714m0 0A10.003 10.003 0 0124 26c4.21 0 7.813 2.602 9.288 6.286M30 14a6 6 0 11-12 0 6 6 0 0112 0zm12 6a4 4 0 11-8 0 4 4 0 018 0zm-28 0a4 4 0 11-8 0 4 4 0 018 0z"
              />
            </svg>
          </div>
          <h2 className="mt-2 text-lg font-medium opacity-50">Create your first audience</h2>
          <p className="mt-1 text-sm text-gray-500">An audience is a collection of subreddits you can search/analyze quickly</p>
        </div>
      ) : ''}
      <div>
        <h3 className="mb-2 text-xs font-semibold text-gray-400 uppercase tracking-wide">Name your custom audience</h3>
        <form className="w-full sm:flex sm:items-center" onSubmit={nameAudience}>
          <label htmlFor="name" className="sr-only">
            Audience Name
          </label>
          <div className="relative rounded-md shadow-sm sm:min-w-0 sm:flex-1">
            <input
              ref={nameInput}
              type="text"
              name="name"
              autoComplete="off"
              className={`bg-gray-800 border-gray-700 placeholder-gray-400 text-white block text-black w-full sm:text-md border-gray-300 rounded-md ${missingName ? 'focus:ring-red-500 focus:border-red-500' : 'focus:ring-cyan-500 focus:border-cyan-500'}`}
              placeholder={`Pick a short name, like "Digital Marketers" or "Movie-Goers" `}
              value={name} onChange={(e) => {
                setName(e.target.value);
                if (e.target.value){
                  setMissingName(false)
                }
              }}
            />
          </div>
          <div className="mt-3 sm:mt-0 sm:ml-4 sm:flex-shrink-0">
            <button
              type="submit"
              className="block w-full text-center px-4 py-2 border border-transparent text-md font-medium rounded-md shadow-sm text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500"
            >
              Find Communities
            </button>
          </div>
        </form>
      </div>

      <div className="mt-8">
        <h3 className="mb-2 text-xs font-semibold text-gray-400 uppercase tracking-wide flex items-center">
          <div>No audience in mind? Explore a curated one, or browse <Link className="underline" to="/audiences/trending/growing/">trending subreddits</Link>.</div>
          <div className="opacity-50 ml-2"></div>
        </h3>
        <PublicAudiences showIcons={showIcons} noPublicScroll={noPublicScroll} />
      </div> 
    </div>
  )
}

const mapStateToProps = state => {
  return {
    
  }
};

const mapDispatchToProps = (dispatch) => {
  // const fetchAudiences = (callback, onError) => {
  //   dispatch(audiencesOperations.fetchAudiences(callback, onError))
  // };
  return {
    // fetchAudiences,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NameAudience);

